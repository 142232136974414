import { calculateOverallConf } from "~/common/utils/dataUtils";

import { ML_SCORE_SLIDER } from "./MLScoreSlider/constants";

export const meetsMLScoreThreshold = (item, selectedMLScoreRanges) =>
  Number(item?.mlScore?.score) >= selectedMLScoreRanges[0];

export const meetsConfidenceThreshold = item => {
  const mlOverallConf = calculateOverallConf(item);
  return item?.isMLAssistedReview ? mlOverallConf > 0 : item?.overallConf > 0;
};

export const handlePrevFrame = ({
  scanResults,
  scanIndex,
  sliderType,
  selectedMLScoreRanges,
  setScanIndex
}) => {
  const thresholdEvaluator =
    sliderType === ML_SCORE_SLIDER
      ? item => meetsMLScoreThreshold(item, selectedMLScoreRanges)
      : meetsConfidenceThreshold;

  const prevFrameIndex = scanResults.findIndex(
    (item, index) => thresholdEvaluator(item) && index > scanIndex
  );
  if (prevFrameIndex !== -1) {
    setScanIndex(prevFrameIndex);
  }
};

export const handleNextFrame = ({
  scanResults,
  scanIndex,
  sliderType,
  selectedMLScoreRanges,
  setScanIndex
}) => {
  const thresholdEvaluator =
    sliderType === ML_SCORE_SLIDER
      ? item => meetsMLScoreThreshold(item, selectedMLScoreRanges)
      : meetsConfidenceThreshold;

  const nextFrameIndex = scanResults.findLastIndex(
    (item, index) => thresholdEvaluator(item) && index < scanIndex
  );

  setScanIndex(Math.max(nextFrameIndex, 0));
};
