import { TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useRef } from "react";

import { useStyles } from "./styles.js";

export const TagTextField = ({
  tags,
  updateTags,
  setTagsFocus,
  displayType
}) => {
  const textInput = useRef(null);

  const { classes } = useStyles({ displayType });

  const handleNew = ({ target: { value }, key }) => {
    const text = value;
    if (key === "Enter" && value.trim().length > 0) {
      textInput.current.value = "";
      if (tags.includes(text)) {
        return;
      }
      updateTags([...tags, text]);
    }
  };

  const handleFocus = () => {
    setTagsFocus(true);
  };

  const handleBlur = () => {
    setTagsFocus(false);
  };

  return (
    <>
      <Typography className={classes.textfieldLabel}>Tags</Typography>
      <div>
        <TextField
          variant="outlined"
          size="small"
          inputRef={textInput}
          onKeyDown={handleNew}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={classes.textfield}
        />
      </div>
    </>
  );
};

TagTextField.propTypes = {
  tags: PropTypes.array,
  updateTags: PropTypes.func,
  setTagsFocus: PropTypes.func,
  displayType: PropTypes.string
};
