import { SOURCE_TAGS } from "@kuva/ui-helpers";

export const REVIEWER_SPECIFIC_TAGS = {
  IG: {
    name: "Ignore",
    code: "IG",
    isValid: false
  }
};
export const ALL_SOURCE_TAGS = [
  ...Object.values(SOURCE_TAGS),
  ...Object.values(REVIEWER_SPECIFIC_TAGS)
]
  .map(source => source.name)
  .sort();
