import { useEffect } from "react";

import { getFloatingNumber } from "@kuva/ui-helpers";

import { calculateOverallConf } from "~/common/utils/dataUtils";
import { useScanResults } from "~/hooks";

const useFrameConfidenceUpdater = ({
  selectedDeviceId,
  scanResultType,
  displayedScanResults,
  scanIndex
}) => {
  const { invalidateDetections, setCurrentFrameConfidence } =
    useScanResults(scanResultType);

  useEffect(() => {
    const selectedScan = displayedScanResults[scanIndex];
    setCurrentFrameConfidence(
      getFloatingNumber(calculateOverallConf(selectedScan))
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanIndex, displayedScanResults, scanResultType]);

  /* Only trigger invalidation on image changes */
  useEffect(() => {
    invalidateDetections(selectedDeviceId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanIndex]);
};

export default useFrameConfidenceUpdater;
