import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: null,
  loading: false,
  isAlarmBeingCreated: false,
  alarmStart: null,
  alarmEnd: null,
  showLeakSourceTool: false,
  alarmLeakSources: {
    leakRois: [],
    leakOrigin: []
  },
  distanceSegment: null,
  leakDistance: null,
  selectedSource: null,
  humanActivity: null
};

const slice = createSlice({
  name: "alarmCreation",
  initialState,
  reducers: {
    setIsAlarmBeingCreated: (state, action) => {
      state.isAlarmBeingCreated = action.payload;
    },
    setAlarmStart: (state, action) => {
      state.alarmStart = action.payload;
    },
    setAlarmEnd: (state, action) => {
      state.alarmEnd = action.payload;
    },
    setShowLeakSourceTool: (state, action) => {
      state.showLeakSourceTool = action.payload;
    },
    setAlarmLeakSources: (state, action) => {
      state.alarmLeakSources = action.payload;
    },
    setDistanceSegment: (state, action) => {
      state.distanceSegment = action.payload;
    },
    setLeakDistance: (state, action) => {
      state.leakDistance = action.payload;
    },
    setSelectedSource: (state, action) => {
      state.selectedSource = action.payload;
    },
    setHumanActivity: (state, action) => {
      state.humanActivity = action.payload;
    },
    resetAlarmCreation: state => {
      Object.assign(state, initialState);
    }
  }
});

export const AlarmCreationActions = {
  ...slice.actions
};

export const AlarmCreationReducer = slice.reducer;
