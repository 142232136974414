import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { selectCameraScanResults } from "~/common/selectors/camera-scan-results-selector";
import { CameraScanResultsActions } from "~/common/store/slices/camera-scan-results";
import {
  DETECTION_DELETE_MODE,
  DETECTION_MODES,
  SCAN_RESULT_TYPES
} from "~/constants/camera";
import { useOrganization } from "~/hooks";

const useScanResults = scanResultType => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedOrg } = useOrganization();

  const cameraScanResults = useSelector(selectCameraScanResults, shallowEqual);
  const { loading, error, tagging, sessions, labeller } = cameraScanResults;
  const organizationId = selectedOrg?.id;

  const getScanResultsSubSlice = () => {
    switch (scanResultType) {
      case SCAN_RESULT_TYPES.TAGGING:
        return tagging;
      case SCAN_RESULT_TYPES.SESSIONS:
        return sessions;
      case SCAN_RESULT_TYPES.LABELLER:
        return labeller;

      default:
        return tagging;
    }
  };

  const subSlice = getScanResultsSubSlice() || {};
  const {
    scanResults = [],
    detectionMode,
    invalidDetections = {},
    currentFrameConfidence = 0,
    keepOrDelete = DETECTION_DELETE_MODE.DELETE
  } = subSlice;

  const isMLAssistedReview = detectionMode === DETECTION_MODES.ML;
  const isDeleteSelected = keepOrDelete === DETECTION_DELETE_MODE.DELETE;

  const invalidateDetections = async deviceId => {
    const invalidDetections =
      cameraScanResults[`${scanResultType}`]?.invalidDetections;

    if (isEmpty(invalidDetections)) return;

    const [[scanResultId, detectionIndices]] =
      Object.entries(invalidDetections) ?? [];

    if (!scanResultId || !detectionIndices) return;

    try {
      await dispatch(
        CameraScanResultsActions.invalidateDetections({
          organizationId,
          deviceId,
          scanResultType,
          scanResultId,
          detectionIndices,
          detectionMode
        })
      ).unwrap();

      enqueueSnackbar("Detections deleted successfully!", {
        variant: "success"
      });
    } catch (error) {
      if (error?.name === "ConditionError") {
        return;
      }

      enqueueSnackbar(
        `Delete detections failed! ${error?.message || "Unknown error"}`,
        {
          variant: "error",
          autoHideDuration: 2000
        }
      );
    }
  };

  const validateDetections = async (deviceId, scanResultId) => {
    try {
      await dispatch(
        CameraScanResultsActions.validateDetections({
          organizationId,
          deviceId,
          scanResultType,
          scanResultId,
          detectionMode
        })
      ).unwrap();

      enqueueSnackbar("Detections restored successfully!", {
        variant: "success"
      });
    } catch (error) {
      enqueueSnackbar(
        `Restore detections failed! ${error?.message || "Unknown error"}`,
        {
          variant: "error",
          autoHideDuration: 2000
        }
      );
    }
  };

  const setKeepOrDelete = keepOrDelete => {
    dispatch(
      CameraScanResultsActions.setKeepOrDelete({ scanResultType, keepOrDelete })
    );
  };

  const setScanResults = scanResults => {
    dispatch(
      CameraScanResultsActions.setScanResults({ scanResultType, scanResults })
    );
  };

  const setDetectionMode = detectionMode => {
    dispatch(
      CameraScanResultsActions.setDetectionMode({
        scanResultType,
        detectionMode
      })
    );
  };

  const setInvalidDetections = invalidDetections => {
    dispatch(
      CameraScanResultsActions.setInvalidDetections({
        scanResultType,
        invalidDetections
      })
    );
  };

  const setCurrentFrameConfidence = currentFrameConfidence => {
    dispatch(
      CameraScanResultsActions.setCurrentFrameConfidence({
        scanResultType,
        currentFrameConfidence
      })
    );
  };

  return {
    error,
    loading,

    isMLAssistedReview,
    isDeleteSelected,

    scanResults,
    setDetectionMode,
    detectionMode,
    invalidDetections,
    currentFrameConfidence,
    keepOrDelete,

    setKeepOrDelete,
    setScanResults,
    setInvalidDetections,
    setCurrentFrameConfidence,

    invalidateDetections,
    validateDetections
  };
};

export default useScanResults;
