import { createAsyncThunk } from "@reduxjs/toolkit";

import CameraAPI from "~/common/apis/CameraAPI";
import { DETECTION_DELETE_MODE } from "~/constants/camera";

const thunk = async payload => {
  const response = await CameraAPI.invalidateDetections(payload);
  return { response, ...payload };
};

const invertIndices = (scan, detectionIndices) => {
  return scan.detections
    .map((detection, index) => index)
    .filter(index => !detectionIndices.includes(index));
};

export const invalidateDetections = createAsyncThunk(
  "camera/invalidateDetections",
  thunk,
  {
    condition: (payload, { getState }) => {
      const state = getState();
      const { scanResultType, scanResultId } = payload;
      state;

      let detectionIndices = payload.detectionIndices;
      const scanResultState = state.cameraScanResultsState[scanResultType];
      const shouldInvertIndices =
        scanResultState.keepOrDelete === DETECTION_DELETE_MODE.KEEP;

      if (shouldInvertIndices) {
        const scan = scanResultState.scanResults.find(
          scanResult => scanResult.id === scanResultId
        );

        payload.detectionIndices = invertIndices(scan, detectionIndices);

        if (payload.detectionIndices.length === 0)
          throw new Error("No detections to invalidate");
      }

      const hasPendingInvalidations =
        scanResultState?.pendingInvalidations?.[scanResultId];
      return !hasPendingInvalidations;
    }
  }
);
