import { useFeatureIsOn } from "@growthbook/growthbook-react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Tooltip,
  Typography
} from "@mui/material";
import { noop } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getFloatingNumber } from "@kuva/ui-helpers";

import { KeepDeleteToggle } from "~/common/components/Bar/components/KeepDeleteToggle";
import CustomDialog from "~/common/components/Dialog/CustomDialog";
import DetectionBox from "~/common/components/Dialog/Detectionbox/DetectionBox";
import { selectIsEditing } from "~/common/selectors/AlarmSelector";
import { getSelectedCamera } from "~/common/selectors/CameraSelector";
import { calculateOverallConf } from "~/common/utils/dataUtils";
import { handleImgUrl } from "~/common/utils/functionUtils";
import { flags } from "~/constants/feature-flags";
import LeakSourceBox from "~/containers/LeakSourceLocationTool/components/LeakSourceBox";
import LeakSourcePoint from "~/containers/LeakSourceLocationTool/components/LeakSroucePoint";
import { useScanResults } from "~/hooks";

import { MissingImage } from "../MissingImage";

import { useStyles } from "./Styles";

const Image = ({
  loading = false,
  selected,
  scanResultType,
  scan,
  imgType,
  event,
  handleFullscreenImage,
  isAlarmBeingCreated,
  handleCreatingAlarm,
  setScanResults,
  selectedPoi,
  handleOpenDistanceMapper,
  confidence,
  invalidDetections,
  setInvalidDetections = noop,
  setConfidence = noop,
  validateDetections = noop
}) => {
  const isSemiAutomaticQuantOn = useFeatureIsOn(flags.SEMI_AUTOMATIC_QUANT);
  const { isMLAssistedReview, isDeleteSelected } =
    useScanResults(scanResultType);
  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [restoreDialogOpen, setRestoreDialogOpen] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [hasImageError, setHasImageError] = useState(false);

  const isAlarmEditing = useSelector(selectIsEditing);
  const img = useRef(null);

  const detections = isMLAssistedReview
    ? scan?.mlDetection?.mlDetections
    : scan?.detections;

  const showDetectionBoxes =
    sessionStorage.getItem("showDetectionBoxes") === "false" ? false : true;

  const open = Boolean(anchorEl);
  const id = open ? "more-popover" : undefined;
  const currentConfidence = selected
    ? confidence
    : getFloatingNumber(calculateOverallConf(scan));

  const selectedCamera = useSelector(getSelectedCamera, shallowEqual);

  const handleCreateAlarm = () => {
    handleCreatingAlarm(scan?.createdOn);
    setAnchorEl(null);
  };

  const handleCreateDistanceSegments = () => {
    handleOpenDistanceMapper();
    setAnchorEl(null);
  };

  const handleImg = imgUrl => {
    return handleImgUrl(imgUrl, imgType);
  };

  const handleRestoreDialogShow = show => {
    setRestoreDialogOpen(show);
  };

  const handleRestoreDetections = () => {
    validateDetections(selectedCamera?.deviceId, scan?.id);
    setRestoreDialogOpen(false);
  };

  const utcTime = scan?.createdOn?.substring(11, 19);
  const hasInvalidTag = detections?.some(
    detection => detection.tag === "invalid"
  );

  const imageURL = handleImg(`${scan?.jpg}?mldetections=${isMLAssistedReview}`);

  useEffect(() => {
    setHasImageError(false);
    setImageLoaded(false);
  }, [imageURL, selected]);

  const isDetectionRenderable =
    imgType === "colDen" &&
    !hasImageError &&
    imageLoaded &&
    detections?.length > 0 &&
    showDetectionBoxes;

  return (
    <div
      style={{
        display: "flex",
        margin: "0 0.5em",
        flexDirection: "column",
        width: selected ? "35%" : "24%"
      }}
    >
      <CustomDialog
        show={restoreDialogOpen}
        title={"Confirm Restore"}
        text={"Are you sure you want to restore all detection?"}
        handleClose={handleRestoreDialogShow.bind(null, false)}
        onCancel={handleRestoreDialogShow.bind(null, false)}
        onConfirm={handleRestoreDetections}
        confirmButtonText={"Restore"}
      />
      <div className={classes.mainWrapper}>
        <Typography style={{ fontSize: selected ? 18 : 14, flex: 1 }}>
          {utcTime}
        </Typography>
        {selected && <KeepDeleteToggle scanResultType={scanResultType} />}

        <div style={{ marginRight: 4 }}>
          {event && <Typography> Event {event} </Typography>}
          <Typography>Confidence: {currentConfidence}</Typography>
        </div>
        <IconButton onClick={e => setAnchorEl(e.currentTarget)} size="small">
          <MoreHorizIcon />
        </IconButton>
        <Popover
          id={id}
          open={open}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <List>
            <Tooltip title="Shortcut: 'Ctrl + Z'" placement="right" arrow>
              <ListItem
                button
                onClick={() => handleCreateAlarm()}
                disabled={
                  isAlarmBeingCreated || isAlarmEditing || selectedPoi === "All"
                }
              >
                <ListItemText>Create Alarm</ListItemText>
              </ListItem>
            </Tooltip>

            {isSemiAutomaticQuantOn && (
              <ListItem
                button
                onClick={() => handleCreateDistanceSegments()}
                disabled={selectedPoi === "All"}
              >
                <ListItemText>Distance Segments</ListItemText>
              </ListItem>
            )}

            <ListItem
              button
              onClick={handleRestoreDialogShow.bind(null, true)}
              disabled={loading || !hasInvalidTag || !showDetectionBoxes}
            >
              <ListItemText>Restore Detections</ListItemText>
            </ListItem>
          </List>
        </Popover>
      </div>
      <div style={{ position: "relative" }}>
        {hasImageError ? (
          <MissingImage />
        ) : (
          <img
            ref={img}
            loading="lazy"
            onLoad={() => setImageLoaded(true)}
            onError={() => {
              setHasImageError(true);
            }}
            key={scan?.id}
            src={imageURL}
            width="313"
            height="410"
            alt={`gas ${imgType}`}
            onClick={() => handleFullscreenImage()}
            style={{
              outline: event !== null ? "1px solid cyan" : "1px solid grey",
              width: "100%",
              height: "auto"
            }}
          />
        )}

        {/* Show detection boxes only if image is loaded and not in error */}
        {isDetectionRenderable &&
          detections?.map((detection, index) => {
            return (
              <DetectionBox
                key={detection?.uuid || index}
                img={img}
                detection={detection}
                index={index}
                scan={scan}
                editable={selected}
                setScanResults={setScanResults}
                setConfidence={setConfidence}
                invalidDetections={invalidDetections}
                setInvalidDetections={setInvalidDetections}
                isDeleteSelected={isDeleteSelected}
              />
            );
          })}

        {!hasImageError &&
          imageLoaded &&
          scan?.leakRois?.length > 0 &&
          scan?.leakRois?.map((leakSource, index) => {
            return (
              <LeakSourceBox
                key={leakSource.id}
                img={img}
                leakSource={leakSource}
                index={index}
                scan={scan}
                editable={selected}
                setScanResults={setScanResults}
                setConfidence={setConfidence}
              />
            );
          })}

        {!hasImageError && scan?.leakOrigin?.length > 0 && (
          <LeakSourcePoint img={img} leakOrigin={scan?.leakOrigin} />
        )}
      </div>
      <Typography>ML Score: {scan?.mlScore?.score || "N/A"}</Typography>
    </div>
  );
};

Image.propTypes = {
  loading: PropTypes.bool.isRequired,
  isAlarmBeingCreated: PropTypes.bool.isRequired,
  event: PropTypes.number,
  handleCreatingAlarm: PropTypes.func.isRequired,
  handleFullscreenImage: PropTypes.func.isRequired,
  handleOpenDistanceMapper: PropTypes.func.isRequired,
  imgType: PropTypes.string.isRequired,
  selectedPoi: PropTypes.string.isRequired,
  scanResultType: PropTypes.string.isRequired,
  scan: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  setScanResults: PropTypes.func.isRequired,
  confidence: PropTypes.number,
  invalidDetections: PropTypes.array.isRequired,
  setInvalidDetections: PropTypes.func.isRequired,
  setConfidence: PropTypes.func.isRequired,
  validateDetections: PropTypes.func.isRequired
};

export default Image;
