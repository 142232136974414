import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import PropTypes from "prop-types";

import { useScanResults } from "~/hooks";

export const KeepDeleteToggle = ({ scanResultType }) => {
  const { keepOrDelete, setKeepOrDelete } = useScanResults(scanResultType);

  const handleToggleChange = (_event, newMode) => {
    if (newMode !== null) {
      setKeepOrDelete(newMode);
    }
  };

  return (
    <ToggleButtonGroup
      sx={{ padding: "0 30px 4px 0" }}
      color="secondary"
      size="small"
      value={keepOrDelete}
      exclusive
      onChange={handleToggleChange}
      aria-label="Keep or Delete"
    >
      <ToggleButton value="keep">Keep selected</ToggleButton>
      <ToggleButton value="delete">Delete selected</ToggleButton>
    </ToggleButtonGroup>
  );
};

KeepDeleteToggle.propTypes = {
  scanResultType: PropTypes.string.isRequired
};
