import PropTypes from "prop-types";
import { GlobalStyle } from "GlobalStyle";

import Image from "~/common/components/Image";

export const ImageCarousel = ({
  scanIndex,
  scanResults,
  imgCommonProps,
  findEvent,
  isAlarmBeingCreated
}) => {
  const { classes: globalStyle } = GlobalStyle();

  const prvImg = scanIndex + 1;
  const currImg = scanIndex;
  const nextImg = scanIndex - 1;

  return (
    <div className={globalStyle.carouselWrapper}>
      {scanIndex < scanResults.length - 1 ? (
        <Image
          {...imgCommonProps}
          scan={scanResults[prvImg]}
          event={findEvent(prvImg)}
          isAlarmBeingCreated={isAlarmBeingCreated}
        />
      ) : (
        <div className={globalStyle.carouselImagePlaceholder} />
      )}

      <Image
        {...imgCommonProps}
        selected={true}
        scan={scanResults[currImg]}
        event={findEvent(currImg)}
      />

      {scanIndex > 0 ? (
        <Image
          {...imgCommonProps}
          scan={scanResults[nextImg]}
          event={findEvent(nextImg)}
          isAlarmBeingCreated={isAlarmBeingCreated}
        />
      ) : (
        <div className={globalStyle.carouselImagePlaceholder} />
      )}
    </div>
  );
};

ImageCarousel.propTypes = {
  scanIndex: PropTypes.number.isRequired,
  scanResults: PropTypes.array.isRequired,
  imgCommonProps: PropTypes.object.isRequired,
  findEvent: PropTypes.func.isRequired,
  setCurrentImageConfidence: PropTypes.func.isRequired,
  isAlarmBeingCreated: PropTypes.bool.isRequired
};
