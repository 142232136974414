import produce from "immer";
import isEmpty from "lodash/isEmpty";
import { useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import {
  getSelectedAlarm,
  selectIsEditing
} from "~/common/selectors/AlarmSelector";
import { getSelectedCamera } from "~/common/selectors/CameraSelector";
import {
  selectCurrentSession,
  selectSelectedDeviceHourlySessions
} from "~/common/selectors/SessionSelector";
import { getCurrentShift } from "~/common/selectors/ShiftSelector";
import { getValueFromSessionStorage } from "~/common/utils/dataUtils";
import { getLeakCoordinates } from "~/containers/LeakSourceLocationTool/Utils";
import { AlarmCreationActions } from "~/common/store/slices/alarm-creation";

const getSessionByAlarmStart = (
  selectedSessions,
  startTime,
  currentSession
) => {
  let closestSession = null;
  let minTimeDifference = Infinity;

  for (const session of selectedSessions) {
    const sessionStartTime = new Date(session.startTime).getTime();
    const inputStartTime = new Date(startTime).getTime();
    const timeDifference = Math.abs(sessionStartTime - inputStartTime);

    if (
      timeDifference < minTimeDifference &&
      inputStartTime >= sessionStartTime &&
      inputStartTime <= sessionStartTime + 3600000 // 1 hour in milliseconds
    ) {
      minTimeDifference = timeDifference;
      closestSession = session;
    }
  }

  if (!closestSession) {
    return currentSession;
  }

  return closestSession;
};

const getInitialAlarmDuration = ({ isAlarmEditing, selectedAlarm }) => {
  let initialAlarmStart = null;
  let initialAlarmEnd = null;
  if (isAlarmEditing && !isEmpty(selectedAlarm)) {
    initialAlarmStart = selectedAlarm?.start;
    initialAlarmEnd = selectedAlarm?.end;
  }
  return { initialAlarmStart, initialAlarmEnd };
};

export const useAlarmCreation = ({
  addEditAlarm,
  eventConf,
  setScanResults,
  selectedPoi
}) => {
  const {
    setIsAlarmBeingCreated,
    setAlarmStart,
    setAlarmEnd,
    setShowLeakSourceTool,
    setAlarmLeakSources,
    setDistanceSegment,
    setLeakDistance,
    setSelectedSource,
    setHumanActivity,
    resetAlarmCreation
  } = AlarmCreationActions;

  const getter = getValueFromSessionStorage;
  const dispatch = useDispatch();

  const currentShift = useSelector(getCurrentShift, shallowEqual);
  const currentSession = useSelector(selectCurrentSession, shallowEqual);
  const selectedDeviceHourlySessions = useSelector(
    selectSelectedDeviceHourlySessions,
    shallowEqual
  );
  const selectedCamera = useSelector(getSelectedCamera, shallowEqual);
  const selectedAlarm = useSelector(getSelectedAlarm, shallowEqual);
  const isAlarmEditing = useSelector(selectIsEditing);
  const { initialAlarmStart, initialAlarmEnd } = getInitialAlarmDuration({
    isAlarmEditing,
    selectedAlarm
  });

  const {
    isAlarmBeingCreated,
    alarmStart,
    alarmEnd,
    showLeakSourceTool,
    alarmLeakSources,
    distanceSegment,
    leakDistance,
    selectedSource,
    humanActivity
  } = useSelector(state => state.alarmCreationState);

  useEffect(() => {
    const isAlarmBeingCreatedFromStorage = getter("isAlarmBeingCreated", false);
    dispatch(setIsAlarmBeingCreated(isAlarmBeingCreatedFromStorage));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getter]);

  useEffect(() => {
    if (initialAlarmStart) dispatch(setAlarmStart(initialAlarmStart));
    if (initialAlarmEnd) dispatch(setAlarmEnd(initialAlarmEnd));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialAlarmStart, initialAlarmEnd, dispatch]);

  useEffect(() => {
    if (isAlarmEditing && selectedAlarm?.leakRois) {
      dispatch(
        setAlarmLeakSources({
          leakRois: selectedAlarm.leakRois,
          leakOrigin: []
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAlarmEditing, selectedAlarm, dispatch]);

  const isInAlarmDateRange = date => {
    const afterStart = +new Date(date) >= +new Date(alarmStart);
    const beforeEnd = +new Date(date) <= +new Date(alarmEnd);

    return afterStart && beforeEnd;
  };

  /**
   * An event handler wrapper to extend the alarm's date range to
   * include the provided date.
   */
  const handleExtendAlarmPeriod = date => () => {
    const beforeStart = +new Date(date) <= +new Date(alarmStart);
    const afterEnd = +new Date(date) >= +new Date(alarmEnd);

    if (beforeStart) {
      dispatch(setAlarmStart(date));
    } else if (afterEnd) {
      dispatch(setAlarmEnd(date));
    }
  };

  /**
   * Submits an alarm to the Alarm API.
   */
  const handleNewAlarm =
    ({
      alarmStart,
      alarmEnd,
      disableNotification = false,
      distanceFromCamera,
      humanActivity,
      numberOfFrames,
      scans,
      tags,
      source,
      isMLAssistedReview
    }) =>
    () => {
      let session = currentSession;
      if (selectedDeviceHourlySessions?.length > 1) {
        session = getSessionByAlarmStart(
          selectedDeviceHourlySessions,
          alarmStart
        );
      }
      const { leakRoisCoordinates, leakOriginCoordinates } =
        getLeakCoordinates(scans);

      const payload = {
        ...(isAlarmEditing &&
          !isEmpty(selectedAlarm) && { id: selectedAlarm?.id }),
        isMLAssistedReview,
        start: alarmStart,
        end: alarmEnd,
        createdOn: new Date().toISOString(),
        deviceId: selectedCamera?.deviceId,
        numberOfFrames,
        overallConf: eventConf,
        deviceName: selectedCamera?.name || selectedCamera?.deviceId,
        orgId: selectedCamera?.orgID,
        orgName: selectedCamera?.orgID
          .replaceAll("-", " ")
          .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()), // capitalize every first letter in word (eg. kuva-canada to Kuva Canada)
        poiOrientation: Number(selectedPoi),
        disableNotification,
        humanActivity,
        tags,
        source,
        shiftId: currentShift?.id,
        sessionId: session?.id,
        leakSource: {
          coords: leakOriginCoordinates,
          rangeMeters: distanceFromCamera ?? null
        },
        leakRois: leakRoisCoordinates,
        falseAlarm: false,
        ...(distanceSegment?.segmentId
          ? { distanceSegmentId: distanceSegment.segmentId }
          : {}),
        ...(distanceSegment?.name
          ? { distanceSegmentName: distanceSegment.name }
          : {}),
        isAllowingDuplicateAlarms:
          JSON.parse(localStorage.getItem("isAllowingDuplicateAlarms")) ?? false
      };

      addEditAlarm(payload);
    };

  /**
   * Shows the alarm creation dialog by initiating its state.
   */
  const handleCreatingAlarm = date => {
    dispatch(setIsAlarmBeingCreated(true));
    dispatch(setAlarmStart(date));
    dispatch(setAlarmEnd(date));
  };

  /**
   * Cancels an alarm in progress and hides all alarm creation controls.
   */
  const handleAlarmCancel = () => {
    dispatch(resetAlarmCreation());
  };

  useEffect(() => {
    setScanResults(
      produce(draft => {
        draft.map((scan, idx) => {
          const withinRange = isInAlarmDateRange(scan.createdOn);
          if (withinRange) {
            draft[idx].leakRois = alarmLeakSources?.leakRois ?? [];
            draft[idx].leakOrigin = alarmLeakSources?.leakOrigin ?? [];
          } else {
            draft[idx].leakRois = [];
            draft[idx].leakOrigin = [];
          }
        });
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alarmLeakSources, alarmStart, alarmEnd, setScanResults]);

  return {
    alarmEnd,
    alarmStart,
    isAlarmBeingCreated,
    showLeakSourceTool,

    handleAlarmCancel,
    handleCreatingAlarm,
    handleExtendAlarmPeriod,
    handleNewAlarm,
    isInAlarmDateRange,

    alarmLeakSources,
    distanceSegment,
    leakDistance,
    selectedSource,
    humanActivity,

    setAlarmEnd: value => dispatch(setAlarmEnd(value)),
    setAlarmLeakSources: value => dispatch(setAlarmLeakSources(value)),
    setAlarmStart: value => dispatch(setAlarmStart(value)),
    setShowLeakSourceTool: value => dispatch(setShowLeakSourceTool(value)),
    setDistanceSegment: value => dispatch(setDistanceSegment(value)),
    setLeakDistance: value => dispatch(setLeakDistance(value)),
    setSelectedSource: value => dispatch(setSelectedSource(value)),
    setHumanActivity: value => dispatch(setHumanActivity(value))
  };
};
