import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import {
  IconButton,
  Slider as MuiSlider,
  Tooltip,
  Typography
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getFloatingNumber } from "@kuva/ui-helpers";

import SliderSearchTimeDialog from "~/common/components/Dialog/SliderSearchTimeDialog";
import SliderSettingsDialog from "~/common/components/Dialog/SliderSettingsDialog";
import { selectSliderType } from "~/common/selectors/AppSettingsSelector";
import {
  calculateOverallConf,
  getValueFromSessionStorage
} from "~/common/utils/dataUtils";

import { checkTimeGap, normalizeTime, unnormalizeTime } from "./utils";
import { MLScoreSlider } from "./MLScoreSlider";
import { ML_SCORE_SLIDER } from "./MLScoreSlider/constants";

export const Slider = ({
  alarms,
  scanIndex,
  setScanIndex,
  selectedScan,
  scanResults,
  findEvent,
  onWheel
}) => {
  const dispatch = useDispatch();

  const sliderType = useSelector(selectSliderType);

  const [eventSliderMarks, setEventSliderMarks] = useState([]);
  const [sliderGradient, setSliderGradient] = useState("");
  const [sliderSettingsAnchorEl, setSliderSettingsAnchorEl] = useState(null);
  const [noiseFloor, setNoiseFloor] = useState(
    getValueFromSessionStorage("noiseFloor", 1000)
  );
  const [noiseFloorMin, setNoiseFloorMin] = useState(
    getValueFromSessionStorage("noiseFloorMin", 50)
  );
  const [noiseFloorColors, setNoiseFloorColors] = useState(
    getValueFromSessionStorage("noiseColors", [
      "#0000FF",
      "#008000",
      "#FFFF00",
      "#FF0000"
    ])
  );
  const [confidenceColors, setConfidenceColors] = useState(
    getValueFromSessionStorage("confColors", [
      "#0000FF",
      "#008000",
      "#FFFF00",
      "#FF0000"
    ])
  );

  const [confidenceValues, setConfidenceValues] = useState(
    getValueFromSessionStorage("confidenceValues", [50, 100, 200])
  );
  const [sliderSearchAnchorEl, setSliderSearchAnchorEl] = useState(null);
  //TODO: continue custom slider exploration for noise floor metric component
  const CustomSlider = withStyles({
    root: {
      height: 18,
      padding: "10px 0"
    },
    thumb: {
      height: 35,
      width: 2,
      backgroundColor: "#fff",
      marginTop: -15,
      marginLeft: -1,
      "&:focus, &:hover, &.Mui-active": {
        boxShadow:
          "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
        "@media (hover: none)": {}
      }
    },
    active: {},
    valueLabel: {
      left: -15,
      textAlign: "center",
      top: -22,
      "& *": {
        background: "transparent",
        color: "white"
      }
    },
    rail: {
      height: 18,
      backgroundImage: sliderGradient
    },
    mark: {
      backgroundColor: "transparent",
      height: 8,
      width: 1,
      marginTop: -3,
      "&:hover": {}
    },
    markActive: {
      opacity: 1
    }
  })(MuiSlider);

  const DefaultSlider = withStyles({})(MuiSlider);

  const currentConfidence = getFloatingNumber(
    calculateOverallConf(selectedScan)
  );

  const handleSliderSettings = event => {
    setSliderSettingsAnchorEl(event.currentTarget);
  };

  const handleSliderSearchDialog = event => {
    setSliderSearchAnchorEl(event.currentTarget);
  };

  const findScanResult = time => {
    for (let i = 0; i < scanResults.length; i++) {
      if (scanResults[i].createdOn.substring(11, 16) === time) {
        return scanResults[i];
      }
    }
  };

  function valueLabelFormat(value) {
    return unnormalizeTime(value);
  }

  function noiseFloorSliderValueFormat(value) {
    let scanTime = unnormalizeTime(value);
    let scanResult = findScanResult(scanTime);
    return scanResult?.noiseFloorMetric?.toFixed(0) ?? "N/A";
  }

  const onSliderChange = (event, value) => {
    for (let i = 0; i < scanResults.length; i++) {
      let normTime = normalizeTime(scanResults[i].createdOn);
      if (normTime === value.toFixed(2)) {
        setScanIndex(i);
      }
    }
  };

  const setSliderType = sliderType => {
    dispatch({
      type: "SET_SLIDER_TYPE",
      sliderType
    });
  };

  const getScanIndexById = useMemo(
    () => id => scanResults.findIndex(item => item.id === id),
    [scanResults]
  );
  //This code updates 'marks' for the slider component
  useEffect(() => {
    let tmp = [];
    let labeledEvents = [];
    for (let i = 0; i < scanResults.length; i++) {
      let duplicateValue = false;
      let val = normalizeTime(scanResults[i].createdOn);
      let eventVal = findEvent(i);
      //We track which events we've already come across, so that we only label one mark in each event
      if (eventVal !== null && !labeledEvents.includes(eventVal)) {
        labeledEvents.push(eventVal);
      } else {
        eventVal = null;
      }
      //We check for duplicate values to prevent non-unique keys
      for (let j = 0; j < tmp.length; j++) {
        if (tmp[j].value === val) {
          duplicateValue = true;
        }
      }
      if (!duplicateValue) {
        tmp.push({ value: val, label: eventVal ?? "" });
      }
    }
    setEventSliderMarks(tmp);
  }, [scanResults]);

  useEffect(() => {
    let backgroundCount = 0;
    let tmpGradient = "linear-gradient(to right";
    if (sliderType === "noiseFloor") {
      tmpGradient += `,${noiseFloorColors[0]} `;
    }
    if (!scanResults) return;
    scanResults
      .slice(0)
      .reverse()
      .forEach((scan, index) => {
        let time = normalizeTime(scan.createdOn);
        let percentage = (time / 24) * 100;
        let color = ",";
        // paddingPercentage extends the range of the color on the gradient so it's large enough to show
        let paddingPercentage;
        if (sliderType === "confidence") {
          const conf = calculateOverallConf(scan);
          if (conf < confidenceValues[0]) {
            color += confidenceColors[0];
            paddingPercentage = 0.15;
            if (backgroundCount < 15) {
              backgroundCount++;
            }
          } else if (conf < confidenceValues[1]) {
            color += confidenceColors[1];
            paddingPercentage = 0.2;
            backgroundCount = 0;
          } else if (conf < confidenceValues[2]) {
            color += confidenceColors[2];
            paddingPercentage = 0.3;
            backgroundCount = 0;
          } else {
            color += confidenceColors[3];
            paddingPercentage = 0.35;
            backgroundCount = 0;
          }
          // Appends color, start, and end position to the gradient
          tmpGradient += `${color} ${percentage.toFixed(2)}% ${(
            percentage +
            // Ending position is offset by paddingPercentage, and scaled down by backgroundCount
            (paddingPercentage - backgroundCount / 100)
          ).toFixed(2)}%`;
          tmpGradient += checkTimeGap(
            index,
            scanResults,
            percentage,
            confidenceColors
          );
        } else if (sliderType === "noiseFloor") {
          if (
            scan.noiseFloorMetric == null ||
            scan.noiseFloorMetric < noiseFloorMin
          ) {
            color += noiseFloorColors[1];
          } else if (scan.noiseFloorMetric < noiseFloor) {
            color += noiseFloorColors[2];
          } else if (scan.noiseFloorMetric >= noiseFloor) {
            color += noiseFloorColors[3];
          } else {
            color += noiseFloorColors[0];
          }

          tmpGradient = tmpGradient.concat(
            `,${noiseFloorColors[0]} ${(percentage - 0.01).toFixed(
              2
            )}% ${color} ${percentage.toFixed(2)}%, ${noiseFloorColors[0]} ${(
              percentage + 0.6
            ).toFixed(2)}%`
          );
        } else if (sliderType === "alarms") {
          let endPercentage;
          let startPercentage;
          for (let i = 0; i < alarms.length; i++) {
            let startTime = normalizeTime(alarms[i].start);
            let endTime = normalizeTime(alarms[i].end);
            startPercentage = (startTime / 24) * 100;
            endPercentage = (endTime / 24) * 100;
            if (scan.createdOn === alarms[i].start) {
              color += confidenceColors[2];
              tmpGradient += `,${confidenceColors[0]} ${(
                startPercentage - 0.2
              ).toFixed(2)}% ${color} ${(startPercentage - 0.05).toFixed(
                2
              )}% ${(endPercentage + 0.05).toFixed(2)}%, ${
                confidenceColors[0]
              } ${(endPercentage + 0.2).toFixed(2)}%`;
            }
          }
          if (tmpGradient.length === 24) {
            tmpGradient +=
              color + confidenceColors[0] + color + confidenceColors[0];
          }
        }
      });
    tmpGradient += ")";
    setSliderGradient(tmpGradient);
  }, [
    scanResults,
    confidenceColors,
    sliderType,
    noiseFloorColors,
    noiseFloor,
    confidenceValues,
    noiseFloorMin,
    alarms
  ]);

  return (
    <div
      onWheel={onWheel}
      style={{
        width: "100%",
        margin: "auto",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        position: "absolute",
        bottom: window.innerHeight < 750 ? -30 : 20
      }}
    >
      <Typography>00:00</Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "85%"
        }}
      >
        {sliderType === "default" && (
          <DefaultSlider
            style={{ width: "100%" }}
            step={null}
            valueLabelDisplay="on"
            valueLabelFormat={valueLabelFormat}
            value={+normalizeTime(selectedScan?.createdOn)}
            marks={eventSliderMarks}
            track={false}
            min={0}
            max={24}
            onChange={onSliderChange}
          />
        )}
        {sliderType === "confidence" && (
          <CustomSlider
            step={null}
            valueLabelDisplay="on"
            valueLabelFormat={currentConfidence}
            value={+normalizeTime(selectedScan.createdOn)}
            marks={eventSliderMarks}
            track={false}
            min={0}
            max={24}
            onChange={onSliderChange}
          />
        )}
        {sliderType === "noiseFloor" && (
          <CustomSlider
            step={null}
            valueLabelDisplay="on"
            valueLabelFormat={noiseFloorSliderValueFormat}
            value={+normalizeTime(selectedScan.createdOn)}
            marks={eventSliderMarks}
            track={false}
            min={0}
            max={24}
            onChange={onSliderChange}
          />
        )}
        {sliderType === "alarms" && (
          <CustomSlider
            step={null}
            valueLabelDisplay="on"
            valueLabelFormat={currentConfidence}
            value={+normalizeTime(selectedScan.createdOn)}
            marks={eventSliderMarks}
            track={false}
            min={0}
            max={24}
            onChange={onSliderChange}
          />
        )}
        {sliderType === ML_SCORE_SLIDER && (
          <MLScoreSlider
            scanResults={scanResults}
            setScanIndex={setScanIndex}
            getScanIndexById={getScanIndexById}
            value={selectedScan}
          />
        )}
      </div>
      <Typography>23:59</Typography>
      <Tooltip title={"Search Time"} placement="top" arrow>
        <IconButton size="small" onClick={handleSliderSearchDialog}>
          <SearchIcon />
        </IconButton>
      </Tooltip>
      <SliderSearchTimeDialog
        open={Boolean(sliderSearchAnchorEl)}
        handleClose={() => setSliderSearchAnchorEl(null)}
        anchorEl={sliderSearchAnchorEl}
        scanIndex={scanIndex}
        setScanIndex={setScanIndex}
        scanResults={scanResults}
        unnormalizeTime={unnormalizeTime}
        normalizeTime={normalizeTime}
      />
      <IconButton size="small" onClick={handleSliderSettings}>
        <MoreVertIcon />
      </IconButton>
      <SliderSettingsDialog
        open={Boolean(sliderSettingsAnchorEl)}
        handleClose={() => setSliderSettingsAnchorEl(null)}
        anchorEl={sliderSettingsAnchorEl}
        sliderType={sliderType}
        setSliderType={setSliderType}
        noiseFloor={noiseFloor}
        setNoiseFloor={setNoiseFloor}
        noiseFloorColors={noiseFloorColors}
        setNoiseFloorColors={setNoiseFloorColors}
        confidenceColors={confidenceColors}
        setConfidenceColors={setConfidenceColors}
        confidenceValues={confidenceValues}
        setConfidenceValues={setConfidenceValues}
        noiseFloorMin={noiseFloorMin}
        setNoiseFloorMin={setNoiseFloorMin}
        scanResults={scanResults}
      />
    </div>
  );
};

Slider.propTypes = {
  alarms: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  scanIndex: PropTypes.number.isRequired,
  setScanIndex: PropTypes.func.isRequired,
  selectedScan: PropTypes.object.isRequired,
  scanResults: PropTypes.array.isRequired,
  findEvent: PropTypes.func.isRequired,
  onWheel: PropTypes.func.isRequired
};
