import { createAsyncThunk } from "@reduxjs/toolkit";

import CameraAPI from "~/common/apis/CameraAPI";

const thunk = async payload => {
  const response = await CameraAPI.validateDetections(payload);
  return { response, ...payload };
};

export const validateDetections = createAsyncThunk(
  "camera/validateDetections",
  thunk
);
