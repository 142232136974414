import { noop } from "lodash";
import PropTypes from "prop-types";

import { useImageStyles } from "./styles";

export const Image = ({
  frame,
  imageRefs,
  alarmFrames,
  imagesLoaded,
  setImagesLoaded,
  handleEditAlarmTime = noop,
  setHoveredScan = noop,
  editingAlarmDate,
  formatISO,
  startDate,
  endDate,
  isMLAssistedReview,
  ...restProps
}) => {
  const isWithinDateRange =
    frame.createdOn >= formatISO(startDate) &&
    frame.createdOn <= formatISO(endDate);

  const { classes } = useImageStyles({
    editingAlarmDate,
    isWithinDateRange
  });

  const imgSrc = isMLAssistedReview
    ? `${frame.jpg}?mldetections=${isMLAssistedReview}`
    : frame.jpg;

  return (
    <img
      className={classes.alarmImage}
      src={imgSrc}
      ref={imageRefs[alarmFrames.findIndex(fr => fr?.id === frame?.id)]}
      loading="lazy"
      onLoad={() => {
        const copy = [...imagesLoaded];
        const FrameIndex = alarmFrames.findIndex(fr => fr?.id === frame?.id);
        copy[FrameIndex] = true;
        setImagesLoaded(copy);
      }}
      alt={frame.createdOn.substring(11, 19)}
      onClick={() => handleEditAlarmTime(frame)}
      onMouseOver={() => setHoveredScan(frame)}
      {...restProps}
    />
  );
};

Image.propTypes = {
  frame: PropTypes.object.isRequired,
  imageRefs: PropTypes.array.isRequired,
  alarmFrames: PropTypes.array.isRequired,
  imagesLoaded: PropTypes.array.isRequired,
  setImagesLoaded: PropTypes.func.isRequired,
  handleEditAlarmTime: PropTypes.func.isRequired,
  setHoveredScan: PropTypes.func.isRequired,
  editingAlarmDate: PropTypes.string.isRequired,
  formatISO: PropTypes.func.isRequired,
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.oneOf([null])
  ]).isRequired,
  endDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.oneOf([null])
  ]).isRequired,
  isMLAssistedReview: PropTypes.bool
};

export default Image;
