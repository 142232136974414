import { Box, Typography, Switch } from "@mui/material";
import { useState, useEffect } from "react";

export const DuplicateAlarmsSetting = () => {
  const [isAllowingDuplicateAlarms, setIsAllowingDuplicateAlarms] =
    useState(false);

  useEffect(() => {
    const storedValue = localStorage.getItem("isAllowingDuplicateAlarms");
    if (storedValue !== null) {
      setIsAllowingDuplicateAlarms(JSON.parse(storedValue));
    }
  }, []);

  const handleToggle = () => {
    const newValue = !isAllowingDuplicateAlarms;
    setIsAllowingDuplicateAlarms(newValue);
    localStorage.setItem("isAllowingDuplicateAlarms", JSON.stringify(newValue));
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        margin: "5px 0"
      }}
    >
      <Typography sx={{ fontSize: "14px" }}>Enable duplicate alarms</Typography>
      <Switch
        checked={isAllowingDuplicateAlarms}
        onChange={handleToggle}
        color="success"
        name="isAllowingDuplicateAlarms"
      />
    </Box>
  );
};
