import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Close";
import { Badge } from "@mui/material";
import { cloneDeep, noop } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { makeStyles } from "@kuva/ui-components";
import { getFloatingNumber } from "@kuva/ui-helpers/src/utils";

import { calculateOverallConf } from "../../../utils/dataUtils";

const useStyles = makeStyles()({
  cyanRectangle: {
    border: "1px solid cyan",
    position: "absolute"
  },
  redRectangle: {
    border: "1px solid red",
    position: "absolute"
  }
});

const DetectionBox = ({
  detection,
  img,
  fullScreenMode,
  index,
  scan,
  editable,
  setConfidence,
  invalidDetections = {},
  setInvalidDetections = noop,
  isDeleteSelected = false
}) => {
  const { classes } = useStyles();
  const [coords, setCoords] = useState({});
  const [validTag, setValidTag] = useState(false);

  /** translates from an XY coordinate system to css attributes we can work with
   * the original object has an 8 integer array representing 4 pairs of X/Y pixel coordinates
   * We translate the four corners to top, left, height and width integers and multiply by the aspect ratio (clientDimensions/naturalDimentions).
   */
  const handleCoords = () => {
    const imgWidth = img?.current?.naturalWidth || 313;
    const imgHeight = img?.current?.naturalHeight || 410;
    const imgRatio = imgWidth / imgHeight;
    const imgScaleX = scan?.imgscaleX || 1;
    if (fullScreenMode) {
      const screenRatio = window.innerWidth / window.innerHeight;
      const predictedWidth =
        screenRatio > imgRatio
          ? parseInt(window.innerHeight * imgRatio)
          : window.innerWidth;
      const predictedHeight =
        screenRatio < imgRatio
          ? parseInt(window.innerWidth / imgRatio)
          : window.innerHeight;
      const xOffset = (window.innerWidth - predictedWidth) / 2;
      const yOffset = (window.innerHeight - predictedHeight) / 2;
      setCoords({
        left:
          (detection.roicoords[0] * (predictedWidth * imgScaleX)) / imgWidth +
          xOffset,
        width:
          (detection.roicoords[2] - detection.roicoords[0]) *
          ((predictedWidth * imgScaleX) / imgWidth),
        top: (detection.roicoords[1] * predictedHeight) / imgHeight + yOffset,
        height:
          (detection.roicoords[5] - detection.roicoords[1]) *
          (predictedHeight / imgHeight)
      });
    } else {
      let width = img?.current?.width * imgScaleX;
      let height = img?.current?.height;
      setCoords({
        left: detection.roicoords[0] * (width / imgWidth),
        width:
          (detection.roicoords[2] - detection.roicoords[0]) *
          (width / imgWidth),
        top: detection.roicoords[1] * (height / imgHeight),
        height:
          (detection.roicoords[5] - detection.roicoords[1]) *
          (height / imgHeight)
      });
    }
  };

  const handleInvalidateToggle = event => {
    event.stopPropagation();

    const updatedToBeDeleted = { ...invalidDetections };

    const scanId = scan?.id;
    if (!scanId || !editable) return;

    const existingEntries = updatedToBeDeleted[scanId] || [];

    const newEntries = existingEntries.includes(index)
      ? existingEntries.filter(i => i !== index)
      : [...existingEntries, index];

    if (newEntries.length > 0) {
      updatedToBeDeleted[scanId] = newEntries;
    } else {
      delete updatedToBeDeleted[scanId];
    }

    setInvalidDetections(updatedToBeDeleted);

    const updatedScan = cloneDeep(scan);
    updatedScan.detections = updatedScan.detections.map((det, i) =>
      updatedToBeDeleted[scanId]?.includes(i) ? { ...det, tag: "invalid" } : det
    );

    setConfidence(getFloatingNumber(calculateOverallConf(updatedScan)));
  };

  useEffect(() => {
    setValidTag(detection.tag !== "invalid" ? true : false);
  }, [detection.tag]);

  useEffect(() => {
    handleCoords();

    window.addEventListener("resize", handleCoords);

    return () => {
      window.removeEventListener("resize", handleCoords);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  if (!validTag) {
    return (
      <div data-testid="invalid-detection" style={{ display: "none" }}></div>
    );
  }

  const KeepOrDeleteIcon = isDeleteSelected ? (
    <DeleteIcon fontSize="medium" style={{ fill: "white" }} />
  ) : (
    <CheckIcon fontSize="medium" style={{ fill: "white" }} />
  );

  return (
    <div data-testid="valid-detection">
      <Badge
        data-testid="detection-badge"
        className={classes.redRectangle}
        style={{
          ...coords,
          display: "flex",
          cursor: editable && "pointer"
        }}
        badgeContent={
          editable && invalidDetections?.[scan.id]?.includes(index)
            ? KeepOrDeleteIcon
            : null
        }
        onClick={event => handleInvalidateToggle(event)}
      />
    </div>
  );
};

DetectionBox.propTypes = {
  detection: PropTypes.object.isRequired,
  img: PropTypes.PropTypes.oneOfType([
    PropTypes.shape({
      current: PropTypes.instanceOf(HTMLInputElement)
    }),
    PropTypes.oneOf([null])
  ]).isRequired,
  fullScreenMode: PropTypes.bool,
  index: PropTypes.number.isRequired,
  scan: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  setConfidence: PropTypes.func,
  invalidDetections: PropTypes.object.isRequired,
  setInvalidDetections: PropTypes.func.isRequired,
  isDeleteSelected: PropTypes.bool
};

export default DetectionBox;
