import { Typography } from "@mui/material";

import broken_hoop from "~/media/broken_hoop.png";

import { useStyles } from "./styles";

export const MissingImage = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <img alt="broken hoop" src={broken_hoop} className={classes.hoopImg} />
        <Typography className={classes.text}>Oops!</Typography>
        <Typography className={classes.text}>Something happened</Typography>
        <Typography className={classes.text}>to this image.</Typography>
      </div>
    </div>
  );
};
