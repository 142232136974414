import axios from "axios";

import BaseAPI from "./BaseApi";

class AlarmAPI extends BaseAPI {
  constructor() {
    super(`${process.env.REACT_APP_KUVA_API_URL}/alarm/v1`, {
      "Ocp-Apim-Subscription-Key": process.env.REACT_APP_REVIEWER_SUBSCRIPTION
    });
  }

  static instance = null;

  static Instance = () => this.instance ?? new AlarmAPI();

  setAuthToken = token =>
    (this.axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`);

  setBaseOrgHeader = baseOrg => {
    this.axiosInstance.defaults.headers.common["X-Organization"] = baseOrg;
  };

  setBaseURI = url => {
    if (this.axiosInstance && process.env.REACT_APP_API_ORIGIN !== "local") {
      this.axiosInstance.defaults.baseURL = `${url}/v1`;
      console.log("AlarmAPI url set to: ", this.axiosInstance.defaults.baseURL);
    }
  };

  get = (deviceId, startDate, endDate, poi, max) => {
    //Check if there are any previous pending requests
    if (typeof this.alarmsCancelToken != typeof undefined) {
      this.alarmsCancelToken.cancel("Operation canceled due to newer request.");
    }

    //Save the cancel token for the current request
    this.alarmsCancelToken = axios.CancelToken.source();

    return this.axiosInstance.get(`alarm/${deviceId}`, {
      cancelToken: this.alarmsCancelToken.token,
      params: {
        ...(startDate ? { start: startDate } : {}),
        ...(endDate ? { end: endDate } : {}),
        ...(poi ? { poi } : {}),
        ...(max ? { max } : { max: 100 }), // maximum 100 until we have some pagination here
        showFalseAlarms: true
      }
    });
  };

  getById = (deviceId, alarmId, streamId = "") =>
    this.axiosInstance.get(`alarm/${deviceId}/${alarmId}?streamId=${streamId}`);

  getByPagination = (deviceId, page, pagesize) =>
    this.axiosInstance.get(`alarm/${deviceId}`, {
      params: {
        page,
        pagesize,
        sort: "start DESC",
        showFalseAlarms: true
      }
    });

  getAlarmsPerMonth = (deviceId, month, year) =>
    this.axiosInstance.get("alarmspermonth", {
      params: { deviceId, month, year, showFalseAlarms: true }
    });

  /**
   * @name upsert
   * @description This function sends a post request to save an alarm
   *
   * @param {string} deviceId - the id of the camera
   * @param {object} alarm - the alarm object to save
   * @param {object} imageProps - optional - additional props for saving the images
   * @returns Promise<alarm> a promise that returns an alarm
   */
  upsert = (deviceId, alarm, imageProps) => {
    console.log(
      `%c calling post alarm: ${this.axiosInstance.defaults.baseURL}/alarm`,
      "color: #9954E3"
    );
    return this.axiosInstance.post(`alarm/${deviceId}`, alarm, {
      params: {
        ...(imageProps?.falsecolorod
          ? { falsecolorod: imageProps.falsecolorod }
          : {}),
        ...(imageProps?.unmasked ? { unmasked: imageProps.unmasked } : {}),
        ...(imageProps?.nonDetections
          ? { nonDetections: imageProps.nonDetections }
          : {}),
        ...(imageProps?.minThreshold
          ? { minThreshold: imageProps.minThreshold }
          : {})
        // ...(max ? { max: max } : {}),
      }
    });
  };

  delete = (deviceId, alarmId) =>
    this.axiosInstance.delete(`alarm/${deviceId}/${alarmId}`);

  getAlarmsPerSession = ({ sessionIds }) =>
    this.axiosInstance.post(`alarmspersession`, {
      sessionIds,
      showFalseAlarms: true
    });

  updateAlarmStatus = payload => {
    const { streamId, alarmId, falseAlarm } = payload;
    return this.axiosInstance.patch(`alarm/${streamId}/${alarmId}/status`, {
      falseAlarm
    });
  };
}

export default AlarmAPI.Instance();
