import BaseAPI from "./BaseApi";
class OrganizationAPI extends BaseAPI {
  static instance = null;

  static Instance = () => {
    if (!this.instance) {
      this.instance = new OrganizationAPI();
    }
    return this.instance;
  };

  constructor() {
    super(`${process.env.REACT_APP_KUVA_API_URL}/organization/v1`, {
      "Ocp-Apim-Subscription-Key": process.env.REACT_APP_REVIEWER_SUBSCRIPTION,
      "X-Organization": "kuva" // Default organization header
    });
  }

  // Allow auth header to be initialized after login
  setAuthToken = token => {
    this.axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  };

  setBaseURI = url => {
    if (this.axiosInstance && process.env.REACT_APP_API_ORIGIN !== "local") {
      this.axiosInstance.defaults.baseURL = `${url}/v1`;
      console.log("OrgAPI url set to: ", this.axiosInstance.defaults.baseURL);
    }
  };

  getOrganizations = which =>
    this.axiosInstance.get(`organizations/${which ?? ""}`);

  listUsers = (orgId, page, pageSize = 50, fields, roles, cancelToken) => {
    const params = new URLSearchParams({ page, per_page: pageSize });
    if (fields) params.append("fields", fields);
    if (roles) params.append("roles", roles);

    return this.axiosInstance.get(`users/${orgId}?${params.toString()}`, {
      cancelToken
    });
  };

  getUser = (orgId, userId) =>
    this.axiosInstance.get(`users/${orgId}/${userId}`);

  createUser = (orgId, userData) =>
    this.axiosInstance.post(`users/${orgId}`, userData);

  updateUser = (orgId, userId, userData) =>
    this.axiosInstance.put(`users/${orgId}/${userId}`, userData);

  resetPassword = email => this.axiosInstance.post(`resetpassword/`, { email });

  deleteUser = (orgId, userId) =>
    this.axiosInstance.delete(`users/${orgId}/${userId}`);
}

export default OrganizationAPI.Instance();
