import { Grid, Typography } from "@mui/material";
import { noop } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import DetectionBox from "~/common/components/Dialog/Detectionbox/DetectionBox";
import { handleImgUrl } from "~/common/utils/functionUtils";
import { useScanResults } from "~/hooks";

import { MissingImage } from "../MissingImage";

import { useStyles } from "./Styles";

export const ExpandedImage = ({
  scanResultType,
  scan,
  event,
  handleFullscreenImage,
  setScanResults,
  invalidDetections,
  setConfidence = noop,
  setInvalidDetections = noop
}) => {
  const { classes } = useStyles();

  const { isMLAssistedReview, isDeleteSelected } =
    useScanResults(scanResultType);

  const [imageLoaded, setImageLoaded] = useState(false);
  const [hasImageError, setHasImageError] = useState(false);
  const columnDensityImage = useRef(null);

  const imageURL = handleImgUrl(
    `${scan?.jpg}?mldetections=${isMLAssistedReview}`,
    "colDen"
  );

  useEffect(() => {
    setHasImageError(false);
    setImageLoaded(false);
  }, [imageURL]);

  const showDetectionBoxes =
    sessionStorage.getItem("showDetectionBoxes") === "false" ? false : true;
  const detections = isMLAssistedReview
    ? scan?.mlDetection?.mlDetections
    : scan?.detections;
  const isDetectionRenderable =
    !hasImageError &&
    imageLoaded &&
    detections?.length > 0 &&
    showDetectionBoxes;

  return (
    <div className={classes.root}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={3}>
          {/** TODO: if there isn't an RGB??? */}
          <div>
            <Typography>RGB</Typography>
            <img
              key={scan.rgb}
              src={scan.rgb}
              width="640"
              height="480"
              alt="rgb_image"
              onClick={() => handleFullscreenImage("rgb")}
              style={{
                width: "100%",
                height: "auto",
                backgroundColor: "#222",
                outline: event !== null ? "1px solid cyan" : "1px solid grey"
              }}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div>
            <Typography>Column density</Typography>
            <div
              style={{
                position: "relative"
              }}
            >
              {hasImageError ? (
                <MissingImage />
              ) : (
                <img
                  ref={columnDensityImage}
                  onLoad={() => setImageLoaded(true)}
                  onError={() => setHasImageError(true)}
                  key={scan.id}
                  src={imageURL}
                  width="313"
                  height="410"
                  alt="gas_image"
                  onClick={() => handleFullscreenImage("colDen")}
                  style={{
                    width: "100%",
                    height: "auto",
                    backgroundColor: "#222",
                    outline:
                      event !== null ? "1px solid cyan" : "1px solid grey"
                  }}
                />
              )}
              {isDetectionRenderable &&
                detections?.map((detection, index) => {
                  return (
                    <DetectionBox
                      key={detection.uuid || index}
                      img={columnDensityImage}
                      detection={detection}
                      scan={scan}
                      editable={true}
                      index={index}
                      setScanResults={setScanResults}
                      setConfidence={setConfidence}
                      invalidDetections={invalidDetections}
                      isDeleteSelected={isDeleteSelected}
                      setInvalidDetections={setInvalidDetections}
                    />
                  );
                })}
            </div>
          </div>
        </Grid>
        <Grid item xs={3}>
          {console.log("scan.jpg", scan.jpg)}
          <div>
            <Typography>Colorized OD</Typography>
            <img
              key={handleImgUrl(scan.jpg, "colOD")}
              src={handleImgUrl(scan.jpg, "colOD")}
              width="313"
              height="410"
              alt="colorOD"
              onClick={() => handleFullscreenImage("colOD")}
              style={{
                width: "100%",
                height: "auto",
                backgroundColor: "#222",
                outline: event !== null ? "1px solid cyan" : "1px solid grey"
              }}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div>
            <Typography>Unmasked</Typography>
            <img
              key={handleImgUrl(scan.jpg, "unmasked")}
              src={handleImgUrl(scan.jpg, "unmasked")}
              width="313"
              height="410"
              alt="unmasked"
              onClick={() => handleFullscreenImage("unmasked")}
              style={{
                width: "100%",
                height: "auto",
                backgroundColor: "#222",
                outline: event !== null ? "1px solid cyan" : "1px solid grey"
              }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

ExpandedImage.propTypes = {
  scanResultType: PropTypes.string.isRequired,
  scan: PropTypes.object.isRequired,
  event: PropTypes.number,
  handleFullscreenImage: PropTypes.func.isRequired,
  setScanResults: PropTypes.func.isRequired,
  invalidDetections: PropTypes.array.isRequired,
  setInvalidDetections: PropTypes.func.isRequired,
  setConfidence: PropTypes.func.isRequired,
  isMLAssistedReview: PropTypes.bool.isRequired,
  isDeleteSelected: PropTypes.bool
};
