import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@mui/material";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import { useEffect } from "react";

import { makeStyles } from "@kuva/ui-components";

import BaseAPI from "~/common/apis/BaseApi";
import { setAuthToken } from "~/common/apis/api";
import { Loading } from "~/common/components/Loading";
import { useOrganization } from "~/hooks";
import { request } from "~/request";

const useStyles = makeStyles()({
  title: {
    color: "white"
  },

  spinnerHold: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: "fixed"
  },
  spinner2: {
    transform: "translate(-50%, -50%)",
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%"
  }
});
const AppInitialize = ({
  setAppInitialized,
  setAppInitializing,
  setAppError
}) => {
  const { classes } = useStyles();
  const { getAccessTokenSilently, logout } = useAuth0();

  const { setOrganizations, selectedOrg, setSelectedOrg } = useOrganization();

  useEffect(() => {
    BaseAPI.setGetAccessTokenSilently(getAccessTokenSilently);
    BaseAPI.setLogout(logout);
  }, [getAccessTokenSilently, logout]);

  const initializeApplication = async () => {
    try {
      //1. Get Auth0 token
      const token = await getAccessTokenSilently({});
      Cookies.set("kuva.auth.jwt", token, { expires: 1 });

      setAuthToken(token);

      //2. Get organizations
      const orgData = await request(
        `${process.env.REACT_APP_KUVA_API_URL}/organization/v2/organizations`
      );
      if (orgData.status === 204 || !orgData.data.length) {
        throw new Error("Default organization not found for this user!");
      }

      if (orgData.data) {
        setOrganizations(orgData.data);
      } else {
        throw new Error("Unable to retrieve organizations!");
      }

      //3. set up user permissions
      // updateAbility(ability, user);
      //4. set app as initialized
      if (selectedOrg) setSelectedOrg(selectedOrg);
      setAppInitialized(true);
      setAppInitializing(false);
    } catch (error) {
      console.error("initializeApplication error: ", error);
      setAppError({
        title: "Error!",
        message: error.message ?? "Unknown error",
        code: 123
      });
    }
  };

  useEffect(() => {
    setAppInitializing(true);
    initializeApplication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.spinnerHold}>
      <div className={classes.spinner2}>
        <Typography variant="h6" className={classes.title}>
          Initializing...
        </Typography>
        <Loading />
      </div>
    </div>
  );
};

AppInitialize.propTypes = {
  setAppInitialized: PropTypes.func.isRequired,
  setAppInitializing: PropTypes.func.isRequired,
  setAppError: PropTypes.func.isRequired
};

export default AppInitialize;
