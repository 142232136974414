import axios from "axios";

class BaseAPI {
  static getAccessTokenSilently = null;
  static logout = null;

  constructor(baseURL, defaultHeaders = {}) {
    this.baseTime = new Date();
    this.getFramesCallStack = [];

    this.axiosInstance = axios.create({
      baseURL,
      timeout: 30000,
      headers: defaultHeaders
    });

    this.setupInterceptor();
  }

  setupInterceptor = () => {
    this.axiosInstance.interceptors.response.use(
      response => response,
      async error => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          if (!BaseAPI.getAccessTokenSilently) {
            console.error("getAccessTokenSilently is not set.");
            return Promise.reject(error);
          }

          try {
            const newToken = await BaseAPI.getAccessTokenSilently();
            this.setAuthToken(newToken);
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            return this.axiosInstance(originalRequest);
          } catch (refreshError) {
            console.error("Failed to refresh token:", refreshError);

            if (refreshError.error === "invalid_grant") {
              console.error(
                "Refresh token is invalid or expired. Logging out..."
              );

              // Force logout if the refresh token is invalid
              if (BaseAPI.logout) {
                BaseAPI.logout();
              } else {
                console.error("Logout function is not set.");
              }
            }

            return Promise.reject(error);
          }
        }
        return Promise.reject(error);
      }
    );
  };

  static setGetAccessTokenSilently = getAccessTokenSilently => {
    BaseAPI.getAccessTokenSilently = getAccessTokenSilently;
  };

  static setLogout = logout => {
    BaseAPI.logout = logout;
  };

  setAuthToken = token => {
    this.axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  };
}

export default BaseAPI;
