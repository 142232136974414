import { combineReducers } from "@reduxjs/toolkit";

import { PoiDistanceSegmentsReducer } from "~/common/store/slices/poi-distance-segments";
import { CameraPresetListsPageReducer } from "~/common/store/slices/camera-preset-lists";
import { CamerasFovReducer } from "~/common/store/slices/camerasFov";
import { CameraScanResultsReducer } from "~/common/store/slices/camera-scan-results";
import { OrganizationsReducer } from "~/common/store/slices/organizations/organizations-slice";
import { AlgorithmRecord } from "~/common/store/slices/algorithm-records";
import { MLScoreReducer } from "~/common/store/slices/ml-score";
import { AlarmCreationReducer } from "~/common/store/slices/alarm-creation";

import alarms from "./alarmsReducer";
import cameras from "./camerasReducer";
import sessions from "./sessionsReducer";
import settings from "./settingsReducer";
import shifts from "./shiftsReducer";

const rootReducer = combineReducers({
  cameraFovState: CamerasFovReducer,
  cameraState: cameras,
  cameraScanResultsState: CameraScanResultsReducer,
  alarmState: alarms,
  shiftState: shifts,
  sessionState: sessions,
  cameraPresetListsState: CameraPresetListsPageReducer,
  applicationSettings: settings,
  organizationState: OrganizationsReducer,
  poiDistanceSegmentState: PoiDistanceSegmentsReducer,
  algorithmRecordState: AlgorithmRecord,
  mlScoreState: MLScoreReducer,
  alarmCreationState: AlarmCreationReducer
});

export default rootReducer;
