import { Button, Dialog, Divider, TextField, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { createRef, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import {
  getAlarmById,
  updateAlarmStatusAction
} from "~/common/actions/alarmsActions";
import CameraAPI from "~/common/apis/CameraAPI";
import CustomDialog from "~/common/components/Dialog/CustomDialog";
import DeleteDialog from "~/common/components/Dialog/DeleteDialog";
import { getAlarmState } from "~/common/selectors/AlarmSelector";
import { getSelectedCamera } from "~/common/selectors/CameraSelector";
import { selectCurrentSession } from "~/common/selectors/SessionSelector";
import { DETECTION_MODES, SCAN_RESULT_TYPES } from "~/constants/camera";
import { useAlgorithmRecords, useOrganization, useScanResults } from "~/hooks";

import { AlarmActions } from "./AlarmActions";
import { AlarmImages } from "./AlarmImages";
import { AlarmMoreInformation } from "./AlarmMoreInformation";
import AlarmQuantificationInfo from "./AlarmQuantificationInfo";
import { EditAlarmDetails } from "./EditAlarmDetails";
import { ImageTimestamp } from "./ImageTimestamp";
import { LoadingIndicator } from "./LoadingIndicator";
import { MultiImageView } from "./MultiImageView/MultiImageView";
import { NoAlarmSelectedMessage } from "./NoAlarmSelectedMessage";
import {
  AlarmDetailsContainer,
  AlarmDetailsPageWrapper,
  LeftColumnWrapper,
  RightColumnWrapper
} from "./styled-components";

const formatISO = date => date?.toISOString()?.substring(0, 19) + "Z";

const AlarmDetails = ({
  deleteAlarm,
  addEditAlarm,
  scresOptions,
  handleDateChange,
  reviewerMode
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    showNonDetections,
    eventConf,
    minuteGap,
    frameMinimum,
    paddedFrames
  } = scresOptions;

  const { deviceId, alarmId, streamId } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const { blobContainer, withScanResults, algoHash } = useAlgorithmRecords();
  const { setDetectionMode } = useScanResults(SCAN_RESULT_TYPES.TAGGING);

  const { selected: alarm, loading: isLoadingAlarm } = useSelector(
    getAlarmState,
    shallowEqual
  );
  const currentSession = useSelector(selectCurrentSession, shallowEqual);
  const { leakRois, leakSource } = alarm || {};
  const [alarmFrames, setAlarmFrames] = useState([]);
  const [alarmFramesNum, setAlarmFramesNum] = useState(0);
  const [hoveredScan, setHoveredScan] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingAlarmDate, setEditingAlarmDate] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [saveChanges, setSaveChanges] = useState(false);
  const imageRefs = alarmFrames.map(() => createRef());
  const [detectionReportID, setDetectionReportID] = useState(
    alarm?.detectionReportID ?? ""
  );
  const [emissionSourceID, setEmissionSourceID] = useState(
    alarm?.emissionSourceID ?? ""
  );
  const [equipmentUnit, setEquipmentUnit] = useState(
    alarm?.equipmentUnit ?? ""
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [relevantFrames, setRelevantFrames] = useState([]);
  const [disabledAlarmText, setDisabledAlarmText] = useState("");
  const [loading, setloading] = useState(false);
  const [disableNotification, setDisableNotification] = useState(
    alarm?.disableNotification ?? false
  );
  const [editNotificationPrompt, setEditNotificationPrompt] = useState(false);
  const [notificationDialogOpen, setNotificationDialogOpen] = useState(false);
  const [humanActivity, setHumanActivity] = useState(alarm?.humanActivity);
  const [selectedSource, setSelectedSource] = useState(alarm?.source);
  const [selectedAlarmTags, setSelectedAlarmTags] = useState(alarm?.tags);
  const [showWarning, setShowWarning] = useState(false);

  const { selectedOrg } = useOrganization();
  const selectedCamera = useSelector(getSelectedCamera, shallowEqual);

  const isMLAssistedReview = alarm?.isMLAssistedReview;
  const detectionMode = isMLAssistedReview
    ? DETECTION_MODES.ML
    : DETECTION_MODES.STANDARD;

  const poi = useMemo(() => {
    return alarm?.poiOrientation ?? null;
  }, [alarm]);

  const getScanResults = async date => {
    setloading(true);
    setAlarmFrames([]);

    const startDate = date.substring(0, 10) + "T00:00:00Z";
    const endDate = date.substring(0, 10) + "T23:59:59Z";
    const response = await CameraAPI.getEvents({
      deviceId: selectedCamera.deviceId,
      showNonDetections,
      startDate,
      endDate,
      eventConf,
      poi,
      minuteGap,
      frameMinimum,
      paddedFrames,
      blobContainer,
      withScanResults,
      algoHash
    });

    return response.data.scanResults;
  };

  useEffect(() => {
    let alarmNum = 0;
    if (startDate && endDate) {
      for (let i = 0; i < alarmFrames.length; i++) {
        if (
          alarmFrames[i].createdOn >= formatISO(startDate) &&
          alarmFrames[i].createdOn <= formatISO(endDate)
        ) {
          alarmNum++;
        }
      }
    }
    setAlarmFramesNum(alarmNum);
  }, [alarmFrames, startDate, endDate]);

  const handleEditingAlarmDate = date => {
    if (date === "start") {
      if (editingAlarmDate === "start") {
        setEditingAlarmDate("");
      } else {
        setEditingAlarmDate("start");
      }
    } else if (date === "end") {
      if (editingAlarmDate === "end") {
        setEditingAlarmDate("");
      } else {
        setEditingAlarmDate("end");
      }
    }
  };

  useEffect(() => {
    const scrollToImage = index => {
      if (imageRefs[index].current) {
        imageRefs[index].current.scrollIntoView();
      }
    };

    if (editingAlarmDate === "start") {
      for (let i = alarmFrames.length - 1; i >= 0; i--) {
        if (alarmFrames[i].createdOn >= formatISO(startDate)) {
          scrollToImage(i);
          return;
        }
      }
    } else if (editingAlarmDate === "end") {
      for (let i = 0; i < alarmFrames.length; i++) {
        if (alarmFrames[i].createdOn <= formatISO(endDate)) {
          scrollToImage(i);
          return;
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingAlarmDate, relevantFrames]);

  const handleSubmit = (deleteNotified = false) => {
    const toUpdate = {
      ...alarm,
      detectionReportID,
      emissionSourceID,
      start: startDate.toISOString(),
      end: endDate?.toISOString() ?? "",
      numberOfFrames: alarmFramesNum,
      disableNotification,
      equipmentUnit,
      tags: selectedAlarmTags,
      humanActivity,
      source: selectedSource
    };

    // remove the collection of alarm frames from the save request
    delete toUpdate.frames;
    if (editNotificationPrompt && alarm.notified && deleteNotified) {
      delete toUpdate.notified;
    }
    addEditAlarm(toUpdate);
  };
  const handleReset = () => {
    setSaveChanges(false);
    setEditingAlarmDate("");
    setStartDate(alarm?.start ? new Date(alarm.start) : null);
    setEndDate(alarm?.end ? new Date(alarm.end) : null);
    setDetectionReportID(alarm?.detectionReportID ?? "");
    setEmissionSourceID(alarm?.emissionSourceID ?? "");
    setEquipmentUnit(alarm?.equipmentUnit ?? "");
    setDisableNotification(Boolean(alarm?.disableNotification));
    setHumanActivity(alarm?.humanActivity ?? "no");
    setSelectedSource(alarm?.source);
    setSelectedAlarmTags(alarm?.tags ?? []);
    setShowWarning(false);
    dispatch({ type: "SET_IS_EDITING", isEditing: false });
  };
  const handleDetectionReportIDChange = e => {
    //validate here!
    setSaveChanges(true);
    setDetectionReportID(e.target.value);
  };
  const handleEmissionSourceIDChange = e => {
    //validate here!
    setSaveChanges(true);
    setEmissionSourceID(e.target.value);
  };

  const handleEquipmentUnit = e => {
    setSaveChanges(true);
    setEquipmentUnit(e.target.value);
  };

  const handleEditAlarmTime = frame => {
    if (editingAlarmDate === "start") {
      setSaveChanges(true);
      setStartDate(new Date(frame.createdOn));
    } else if (editingAlarmDate === "end") {
      setSaveChanges(true);
      setEndDate(new Date(frame.createdOn));
    }
  };

  const handleToggleAlarmStatus = () => {
    const { streamId } = alarm;
    const newAlarmStatus = "falseAlarm" in alarm ? !alarm.falseAlarm : true;
    dispatch(
      updateAlarmStatusAction({
        streamId,
        alarmId: alarm.id,
        falseAlarm: newAlarmStatus
      })
    )
      .then(() => {
        dispatch({
          type: "SET_ALARM_STATUS",
          payload: {
            alarmId: alarm.id,
            alarmStatus: newAlarmStatus
          }
        });
        enqueueSnackbar("Alarm successfully updated.", {
          variant: "success"
        });
      })
      .catch(e => {
        console.error("Error:", e);
        enqueueSnackbar("Failed to update alarm status.", {
          variant: "error"
        });
      });
  };

  useEffect(() => {
    if (editingAlarmDate) {
      if (startDate?.toISOString() > endDate?.toISOString()) {
        setSaveChanges(false);
        setDisabledAlarmText("Start time cannot be greater than End time");
        setShowWarning(true);
      } else {
        setDisabledAlarmText("Choose valid Start and End Times");
        setShowWarning(false);
      }
    } else {
      setDisabledAlarmText("");
    }
  }, [startDate, endDate, editingAlarmDate]);

  useEffect(() => {
    handleReset();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alarm]);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const bodyText = () => {
    return `
      DetectionReportID: ${alarm.detectionReportID || "——"}
      DetectionReportDateTime: ${alarm.start}
      EmissionStartDateTime: ${alarm.end || "——"}
      EmissionSourceID: ${alarm.emissionSourceID || "——"}
      Gas: Methane
      EquipmentUnit: ${alarm.equipmentUnit || "——"}
      Latitude1: ——
      Longitude1: ——
      EmissionRate: ——
      EmissionRateUpper: ——
      EmissionRateLower: ——
      `;
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteAlarm = async () => {
    deleteAlarm(alarm.id);
    setDeleteDialogOpen(false);
  };

  useEffect(() => {
    //TODO: Do we really need 24h of scans, what happens if start is at 23:59?
    if (alarm)
      getScanResults(alarm.start)
        .then(setAlarmFrames)
        .finally(() => setloading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alarm?.start]);

  useEffect(() => {
    let frames = alarmFrames
      .slice(0)
      .reverse()
      .filter(
        frame =>
          (frame.createdOn >= formatISO(startDate) &&
            frame.createdOn <= formatISO(endDate)) ||
          editingAlarmDate !== ""
      );
    setRelevantFrames(frames);
  }, [alarmFrames, startDate, endDate, editingAlarmDate]);

  useEffect(() => {
    if (editingAlarmDate === "start") {
      const startFrame = alarmFrames.find(
        frame => frame.createdOn === formatISO(startDate)
      );
      setHoveredScan(startFrame ?? relevantFrames?.[0]);
    } else if (editingAlarmDate === "end") {
      const endFrame = alarmFrames.find(
        frame => frame.createdOn === formatISO(endDate)
      );
      setHoveredScan(endFrame ?? relevantFrames?.[0]);
    } else {
      setHoveredScan(relevantFrames?.[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relevantFrames, editingAlarmDate]);

  const handleEditSave = (deleteNotified = false) => {
    setSaveChanges(false);
    setEditingAlarmDate("");
    handleSubmit(deleteNotified);
  };

  const handleAlarmSave = () => {
    if (editNotificationPrompt) {
      setNotificationDialogOpen(true);
    } else {
      handleEditSave(false);
    }
  };

  const handleNotificationDialogClose = () => {
    setNotificationDialogOpen(false);
  };

  const handleNotificationDialogResponse = response => {
    handleEditSave(response);
    setNotificationDialogOpen(false);
  };

  const hoveredScanIndex = useMemo(() => {
    return alarmFrames?.findIndex(scan => scan?.id === hoveredScan?.id);
  }, [alarmFrames, hoveredScan]);

  // TODO: (jan) create a form object and only one Change handler for MUI-Input fields
  const handleNotification = event => {
    setDisableNotification(event.target.checked);
  };

  const handleSelectedSourceChange = (_event, newValue) => {
    if (newValue === "Tanker Truck") {
      setDisableNotification(true);
      setHumanActivity("yes");
    } else {
      setDisableNotification(false);
      setHumanActivity("no");
    }

    setSelectedSource(newValue);
    setSaveChanges(true);
  };

  const handleHumanActivityChange = event => {
    const isHumanActivity = event.target.value;
    setDisableNotification(isHumanActivity === "yes");
    setHumanActivity(isHumanActivity);
    setSaveChanges(true);
  };

  // Check if disable notification was changed to true and notification was already sent
  useEffect(() => {
    if (editingAlarmDate) {
      if (!disableNotification && alarm?.disableNotification !== true) {
        setEditNotificationPrompt(true);
      } else {
        setEditNotificationPrompt(false);
      }
    }
  }, [alarm, disableNotification, editingAlarmDate]);

  const handleBackToAlarm = action => {
    handleDateChange(new Date(hoveredScan.createdOn));
    const eventsPoi = alarm.poiOrientation ?? "All";

    sessionStorage.setItem("selectedPoi", eventsPoi);
    sessionStorage.setItem("scanIndex", hoveredScanIndex);

    action === "MODIFY_ALARM" &&
      dispatch({ type: "SET_IS_EDITING", isEditing: true });

    setDetectionMode(detectionMode);

    history.push({
      pathname: `/events/${selectedOrg?.id}/${selectedCamera?.deviceId}`, // To
      state: {
        from: {
          action,
          eventsPoi: eventsPoi,
          scanIndex: hoveredScanIndex,
          pathname: history?.location?.pathname, // from
          deviceId: selectedCamera?.deviceId
        }
      }
    });
  };

  useEffect(() => {
    // on router params change
    if (deviceId && alarmId) {
      // call the backend to fetch the alarm and save it to redux
      dispatch(getAlarmById(deviceId, alarmId, streamId));
    } else {
      dispatch({ type: "FETCH_ALARM_BY_ID_SUCCESS", alarm: null });
    }

    !deviceId && dispatch({ type: "FETCH_ALARM_BY_ID_SUCCESS", alarm: null });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCamera, deviceId, alarmId]);

  const handleGoBackToSession = () => {
    history.push({
      pathname: `/session-events/${selectedOrg?.id}/${selectedCamera?.deviceId}`, // To
      state: {
        from: {
          action: "CONTINUE_SESSION",
          eventsPoi: sessionStorage.getItem("selectedPoi") ?? "All",
          scanIndex: sessionStorage.getItem("scanIndex") ?? 0,
          pathname: history?.location?.pathname, // from
          deviceId: selectedCamera?.deviceId
        }
      }
    });
  };

  if (!alarm)
    return (
      <NoAlarmSelectedMessage
        reviewerMode={reviewerMode}
        handleGoBackToSession={handleGoBackToSession}
        currentSession={currentSession}
      />
    );

  return (
    <>
      <AlarmDetailsPageWrapper>
        <AlarmActions
          alarm={alarm}
          poi={poi}
          editingAlarmDate={editingAlarmDate || saveChanges}
          handleNotification={handleNotification}
          disableNotification={disableNotification}
          handleBackToAlarm={handleBackToAlarm}
          hoveredScan={hoveredScan}
          setDeleteDialogOpen={setDeleteDialogOpen}
          handleDialogOpen={handleDialogOpen}
          handleToggleAlarmStatus={handleToggleAlarmStatus}
          isLoadingAlarm={isLoadingAlarm}
        />
        <Divider variant="middle" />
        <EditAlarmDetails
          humanActivity={humanActivity}
          handleHumanActivityChange={handleHumanActivityChange}
          detectionReportID={detectionReportID}
          handleDetectionReportIDChange={handleDetectionReportIDChange}
          emissionSourceID={emissionSourceID}
          handleEmissionSourceIDChange={handleEmissionSourceIDChange}
          equipmentUnit={equipmentUnit}
          handleEquipmentUnit={handleEquipmentUnit}
          handleEditingAlarmDate={handleEditingAlarmDate}
          editingAlarmDate={editingAlarmDate}
          startDate={startDate}
          showWarning={showWarning}
          endDate={endDate}
          saveChanges={saveChanges}
          handleReset={handleReset}
          handleAlarmSave={handleAlarmSave}
          disabledAlarmText={disabledAlarmText}
        />
        <Divider variant="middle" />
        {loading ? (
          <LoadingIndicator />
        ) : (
          <AlarmDetailsContainer>
            <LeftColumnWrapper>
              <MultiImageView
                hoveredScan={hoveredScan}
                leakRois={leakRois} // possibly declare in individual file?
                leakSource={leakSource} // possibly declare in individual file?
                isMLAssistedReview={isMLAssistedReview}
              />
              <ImageTimestamp createdOn={hoveredScan?.createdOn} />
              <AlarmQuantificationInfo
                alarm={alarm}
                selectedSource={selectedSource}
                handleSelectedSourceChange={handleSelectedSourceChange}
              />
              {reviewerMode === "REVIEWER-SESSION" &&
                !isEmpty(currentSession) && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleGoBackToSession}
                  >
                    Go back to session
                  </Button>
                )}
              <AlarmMoreInformation hoveredScan={hoveredScan} />
            </LeftColumnWrapper>
            <RightColumnWrapper>
              <AlarmImages
                alarmFrames={alarmFrames}
                alarmFramesNum={alarmFramesNum}
                relevantFrames={relevantFrames}
                imageRefs={imageRefs}
                handleEditAlarmTime={handleEditAlarmTime}
                setHoveredScan={setHoveredScan}
                editingAlarmDate={editingAlarmDate}
                formatISO={formatISO}
                startDate={startDate}
                endDate={endDate}
                leakRois={leakRois}
                leakSource={leakSource}
                isMLAssistedReview={isMLAssistedReview}
              />
            </RightColumnWrapper>
          </AlarmDetailsContainer>
        )}
      </AlarmDetailsPageWrapper>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <Typography>Generated Report</Typography>
        <TextField
          variant="standard"
          style={{ width: "300px" }}
          multiline={true}
          defaultValue={bodyText()}
        />
        <Button onClick={() => navigator.clipboard.writeText(bodyText())}>
          Copy to Clipboard
        </Button>
      </Dialog>
      <DeleteDialog
        show={deleteDialogOpen}
        tile={"Confirm Delete"}
        text={`Are you sure you want to delete alarm "${alarm.id}"?`}
        handleClose={handleDeleteDialogClose}
        onCancel={handleDeleteDialogClose}
        onDelete={handleDeleteAlarm}
      />
      <CustomDialog
        show={notificationDialogOpen}
        title={"Email Notification"}
        text={"Re-evaluate alarm for notification threshold?"}
        handleClose={handleNotificationDialogClose}
        onCancel={() => handleNotificationDialogResponse(false)}
        onConfirm={() => handleNotificationDialogResponse(true)}
        confirmButtonText={"Yes"}
        declineButtonText={"No"}
      />
    </>
  );
};

AlarmDetails.propTypes = {
  deleteAlarm: PropTypes.func.isRequired,
  addEditAlarm: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  scresOptions: PropTypes.shape({
    showNonDetections: PropTypes.bool.isRequired,
    eventConf: PropTypes.number.isRequired,
    minuteGap: PropTypes.number.isRequired,
    frameMinimum: PropTypes.number.isRequired,
    paddedFrames: PropTypes.number.isRequired
  }).isRequired,
  reviewerMode: PropTypes.string
};

export default AlarmDetails;
