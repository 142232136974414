import BaseAPI from "./BaseApi";

class AlgorithmAPI extends BaseAPI {
  static instance = null;

  static Instance = () => {
    if (!this.instance) {
      this.instance = new AlgorithmAPI(); // Interceptor is set up in the constructor
    }
    return this.instance;
  };

  constructor() {
    super(`${process.env.REACT_APP_KUVA_API_URL}/cloudalgos/v1`, {
      "Ocp-Apim-Subscription-Key": process.env.REACT_APP_REVIEWER_SUBSCRIPTION
    });
  }

  setAuthToken = token => {
    this.axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  };

  setBaseOrgHeader = baseOrg => {
    this.axiosInstance.defaults.headers.common["X-Organization"] = baseOrg;
  };

  setBaseURI = url => {
    if (this.axiosInstance && process.env.REACT_APP_API_ORIGIN !== "local") {
      this.axiosInstance.defaults.baseURL = `${url}/v1`;
    }
  };

  upsertLabel = (deviceId, scresId, payload) =>
    this.axiosInstance.post(`label/${deviceId}/${scresId}`, payload);

  getAlgorithmRecords = () => this.axiosInstance.get(`algorithm-records`);
}

export default AlgorithmAPI.Instance();
