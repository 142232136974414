import {
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  Close as CloseIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon
} from "@mui/icons-material";
import { noop } from "lodash";
import { Button, Dialog, IconButton, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import DetectionBox from "~/common/components/Dialog/Detectionbox/DetectionBox";

import { useStyles } from "./styles";

export const FullscreenDialog = ({
  fullScreenImg,
  setFullScreenImg,
  selectedScan,
  fullScreenImgRef,
  fullScreenImgUrl,
  fullScreenImgType,
  showDetectionBoxes,
  setScanResults,
  scanIndex,
  scanResults,
  handleLeftArrow,
  handleRightArrow,
  invalidDetections,
  setConfidence = noop,
  setInvalidDetections = noop,
  isMLAssistedReview,
  isDeleteSelected = false
}) => {
  const { classes } = useStyles();

  const detections = isMLAssistedReview
    ? selectedScan?.mlDetection?.mlDetections
    : selectedScan?.detections;

  const imgQueryString =
    isMLAssistedReview && fullScreenImgType === "colDen"
      ? `?mldetections=${isMLAssistedReview}`
      : "";

  return (
    <Dialog
      fullScreen={true}
      open={fullScreenImg}
      onClose={() => setFullScreenImg(false)}
    >
      <TransformWrapper defaultScale={1} style={{ position: "relative" }}>
        {({ zoomIn, zoomOut, resetTransform }) => (
          <>
            <div
              className={classes.mainWrapper}
              onMouseOver={e => (e.currentTarget.style.borderColor = "#ffffff")}
              onMouseOut={e =>
                (e.currentTarget.style.borderColor = "transparent")
              }
            >
              <IconButton onClick={() => zoomOut()} size="large">
                <ZoomOutIcon fontSize="large" />
              </IconButton>
              <IconButton onClick={() => zoomIn()} size="large">
                <ZoomInIcon fontSize="large" />
              </IconButton>
              <Button
                onClick={() => resetTransform()}
                style={{
                  color: "#ffffff"
                }}
              >
                Reset
              </Button>
            </div>
            <div
              style={{
                position: "absolute",
                left: "40px",
                top: "100px",
                zIndex: 4,
                padding: "8px",
                backgroundColor: "#000000"
              }}
            >
              <Typography style={{ fontSize: 14 }}>
                <span style={{ display: "block" }}>TIME:</span>
                {selectedScan?.createdOn?.substring(11, 19) /* Get UTC time */}
              </Typography>
            </div>
            <TransformComponent>
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "100%"
                }}
              >
                <img
                  ref={fullScreenImgRef}
                  src={`${fullScreenImgUrl}${imgQueryString}`}
                  alt="img"
                  style={{
                    height: "100vh",
                    width: "100vw",
                    objectFit: "contain"
                  }}
                />
                {fullScreenImgType === "colDen" &&
                  detections?.length > 0 &&
                  showDetectionBoxes &&
                  detections.map((detection, index) => {
                    return (
                      <DetectionBox
                        key={detection.uuid}
                        img={fullScreenImgRef}
                        detection={detection}
                        fullScreenMode={true}
                        index={index}
                        scan={selectedScan}
                        editable={true}
                        setScanResults={setScanResults}
                        setConfidence={setConfidence}
                        invalidDetections={invalidDetections}
                        setInvalidDetections={setInvalidDetections}
                        isDeleteSelected={isDeleteSelected}
                      />
                    );
                  })}
              </div>
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
      <CloseIcon
        onClick={() => setFullScreenImg(false)}
        style={{
          fontSize: "4rem",
          position: "absolute",
          right: "20px",
          top: "20px",
          cursor: "pointer",
          color: "#ffffff"
        }}
      />
      <div
        style={{
          margin: "auto",
          position: "absolute",
          width: "98vw",
          left: 0,
          right: 0,
          top: "50%",
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <IconButton
          disabled={scanIndex === scanResults.length - 1}
          onClick={handleLeftArrow}
          aria-label="left"
          style={{
            border: scanIndex !== scanResults.length - 1 && "5px solid black"
          }}
          size="large"
        >
          <ArrowLeftIcon fontSize="large" />
        </IconButton>
        <IconButton
          disabled={scanIndex === 0}
          onClick={handleRightArrow}
          aria-label="right"
          style={{
            border: scanIndex !== 0 && "5px solid black"
          }}
          size="large"
        >
          <ArrowRightIcon fontSize="large" />
        </IconButton>
      </div>
    </Dialog>
  );
};

FullscreenDialog.propTypes = {
  fullScreenImg: PropTypes.bool.isRequired,
  setFullScreenImg: PropTypes.func.isRequired,
  selectedScan: PropTypes.object.isRequired,
  fullScreenImgRef: PropTypes.oneOfType([
    PropTypes.shape({
      current: PropTypes.instanceOf(HTMLInputElement)
    }),
    PropTypes.oneOf([null])
  ]).isRequired,
  fullScreenImgUrl: PropTypes.string,
  fullScreenImgType: PropTypes.string.isRequired,
  showDetectionBoxes: PropTypes.bool.isRequired,
  setScanResults: PropTypes.func.isRequired,
  scanIndex: PropTypes.number.isRequired,
  scanResults: PropTypes.array.isRequired,
  handleLeftArrow: PropTypes.func.isRequired,
  handleRightArrow: PropTypes.func.isRequired,
  invalidDetections: PropTypes.array.isRequired,
  setInvalidDetections: PropTypes.func.isRequired,
  setConfidence: PropTypes.func.isRequired,
  isMLAssistedReview: PropTypes.bool.isRequired,
  isDeleteSelected: PropTypes.bool
};
