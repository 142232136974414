import { createSlice } from "@reduxjs/toolkit";

import { getFloatingNumber } from "@kuva/ui-helpers";

import { calculateOverallConf } from "~/common/utils/dataUtils";
import {
  DETECTION_DELETE_MODE,
  DETECTION_MODES,
  SCAN_RESULT_TYPES
} from "~/constants/camera";

import { invalidateDetections } from "../thunks/invalidate-detections";
import { validateDetections } from "../thunks/validate-detections";

function getStoredDetectionModes() {
  const savedModes = sessionStorage.getItem("detectionModes");
  return savedModes ? JSON.parse(savedModes) : {};
}

const storedDetectionModes = getStoredDetectionModes();

const initialState = {
  error: null,
  loading: false,
  [SCAN_RESULT_TYPES.TAGGING]: {
    scanResults: [],
    detectionMode:
      storedDetectionModes[SCAN_RESULT_TYPES.TAGGING] ||
      DETECTION_MODES.STANDARD,
    invalidDetections: {},
    pendingInvalidations: {},
    currentFrameConfidence: 0,
    keepOrDelete: DETECTION_DELETE_MODE.DELETE
  },
  [SCAN_RESULT_TYPES.SESSIONS]: {
    scanResults: [],
    detectionMode:
      storedDetectionModes[SCAN_RESULT_TYPES.SESSIONS] ||
      DETECTION_MODES.STANDARD,
    invalidDetections: {},
    pendingInvalidations: {},
    currentFrameConfidence: 0,
    keepOrDelete: DETECTION_DELETE_MODE.DELETE
  },
  [SCAN_RESULT_TYPES.LABELLER]: {
    scanResults: [],
    detectionMode:
      storedDetectionModes[SCAN_RESULT_TYPES.LABELLER] ||
      DETECTION_MODES.STANDARD,
    invalidDetections: {},
    pendingInvalidations: {},
    currentFrameConfidence: 0,
    keepOrDelete: DETECTION_DELETE_MODE.DELETE
  }
};

const enhanceScanResultsWithMLData = (scanResults, detectionMode) => {
  const isMLAssistedReview = detectionMode === DETECTION_MODES.ML;

  return scanResults.map(item => {
    const updatedItem = { ...item, isMLAssistedReview };

    if (isMLAssistedReview && item.mlDetection?.mlDetections?.length > 0) {
      const mlConfidence = item.mlDetection.mlDetections.reduce(
        (sum, detection) => sum + (detection.sumconf || 0),
        0
      );
      const standardConfidence = item.overallConf;
      updatedItem.mlOverallConf = mlConfidence * (standardConfidence > 0);
    } else {
      updatedItem.mlOverallConf = 0;
    }

    return updatedItem;
  });
};

const updateDetections = (
  scanResultsArr,
  scanResultId,
  detectionMode,
  tag,
  detectionIndices = null
) => {
  const foundIndex = scanResultsArr.findIndex(
    scanResult => scanResult.id === scanResultId
  );
  if (foundIndex === -1) {
    throw new Error(`Scan result with ID ${scanResultId} not found`);
  }

  const targetScanResult = scanResultsArr[foundIndex];

  if (detectionMode === DETECTION_MODES.STANDARD) {
    targetScanResult.detections ??= [];

    targetScanResult.detections = targetScanResult.detections.map(
      (detection, index) => ({
        ...detection,
        tag: detectionIndices
          ? detectionIndices.includes(index)
            ? tag
            : detection.tag
          : tag
      })
    );
  } else if (detectionMode === DETECTION_MODES.ML) {
    targetScanResult.mlDetection ??= { mlDetections: [] };

    targetScanResult.mlDetection.mlDetections =
      targetScanResult?.mlDetection?.mlDetections.map((detection, index) => ({
        ...detection,
        tag: detectionIndices
          ? detectionIndices.includes(index)
            ? tag
            : detection.tag
          : tag
      }));
  }

  return targetScanResult;
};

const slice = createSlice({
  name: "camera-scan-results",
  initialState,
  reducers: {
    setScanResults: (state, action) => {
      const { scanResultType, scanResults } = action.payload;
      state[scanResultType].scanResults = enhanceScanResultsWithMLData(
        scanResults,
        state[scanResultType].detectionMode
      );
    },
    setDetectionMode: (state, action) => {
      const { scanResultType, detectionMode } = action.payload;
      state[scanResultType].detectionMode = detectionMode;
      state[scanResultType].scanResults = enhanceScanResultsWithMLData(
        state[scanResultType].scanResults,
        detectionMode
      );

      const currentModes = getStoredDetectionModes();
      currentModes[scanResultType] = detectionMode;
      sessionStorage.setItem("detectionModes", JSON.stringify(currentModes));

      state[scanResultType].pendingInvalidations = {};
      state[scanResultType].invalidDetections = {};
    },
    setKeepOrDelete: (state, action) => {
      const { scanResultType, keepOrDelete } = action.payload;
      state[scanResultType].keepOrDelete = keepOrDelete;
    },
    setInvalidDetections: (state, action) => {
      const { scanResultType, invalidDetections } = action.payload;
      state[scanResultType].invalidDetections = invalidDetections;
    },
    setCurrentFrameConfidence: (state, action) => {
      const { scanResultType, currentFrameConfidence } = action.payload;
      state[scanResultType].currentFrameConfidence = currentFrameConfidence;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(invalidateDetections.pending, (state, action) => {
        state.loading = true;

        const { scanResultType, scanResultId } = action.meta.arg;
        state[scanResultType].pendingInvalidations[scanResultId] = true;
      })
      .addCase(invalidateDetections.fulfilled, (state, action) => {
        state.loading = false;
        const { detectionIndices, scanResultId, scanResultType } =
          action.payload;
        const scanResultsArr = state[scanResultType].scanResults;

        updateDetections(
          scanResultsArr,
          scanResultId,
          state[scanResultType].detectionMode,
          "invalid",
          detectionIndices
        );

        delete state[scanResultType].invalidDetections[scanResultId];
        delete state[scanResultType].pendingInvalidations[scanResultId];
      })
      .addCase(invalidateDetections.rejected, (state, action) => {
        const { scanResultType } = action.meta.arg;

        const error = {
          error: true,
          errorOn: action.payload
        };
        state.error = error;
        state.loading = false;

        state[scanResultType].pendingInvalidations = {};
        state[scanResultType].invalidDetections = {};
      })

      .addCase(validateDetections.pending, state => {
        state.loading = true;
      })
      .addCase(validateDetections.fulfilled, (state, action) => {
        state.loading = false;
        const { scanResultId, scanResultType } = action.payload;
        const scanResultsArr = state[scanResultType].scanResults;

        const targetScanResult = updateDetections(
          scanResultsArr,
          scanResultId,
          state[scanResultType].detectionMode,
          "valid"
        );

        state[scanResultType].currentFrameConfidence = getFloatingNumber(
          calculateOverallConf(targetScanResult)
        );

        delete state[scanResultType].invalidDetections[scanResultId];
      })
      .addCase(validateDetections.rejected, (state, action) => {
        const { scanResultType } = action.meta.arg;

        const error = {
          error: true,
          errorOn: action.payload
        };
        state.error = error;
        state.loading = false;

        state[scanResultType].invalidDetections = {};
      });
  }
});

export const CameraScanResultsActions = {
  ...slice.actions,
  invalidateDetections,
  validateDetections
};

export const CameraScanResultsReducer = slice.reducer;
