import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import PropTypes from "prop-types";

export const DetectionToggle = ({
  detectionMode,
  handleDetectionModeChange,
  disabled
}) => {
  const onToggleChange = (event, newMode) => {
    if (newMode !== null) {
      handleDetectionModeChange(event, newMode);
    }
  };

  return (
    <ToggleButtonGroup
      sx={{ marginTop: "5px" }}
      color="primary"
      size="small"
      value={detectionMode}
      exclusive
      onChange={onToggleChange}
      aria-label="Detection Mode"
      disabled={disabled}
    >
      <ToggleButton value="standard-detection">Standard Detection</ToggleButton>
      <ToggleButton value="ml-detection">ML Detection</ToggleButton>
    </ToggleButtonGroup>
  );
};

DetectionToggle.propTypes = {
  detectionMode: PropTypes.string.isRequired,
  handleDetectionModeChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};
