import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PointsOfInterestPanorama } from "@kuva/ui-components";

import { CameraFovActions } from "~/common/store/slices/camerasFov";

export const PanoramaView = ({
  selectedCamera,
  selectedPoi,
  setSelectedPoi,
  poiList
}) => {
  const dispatch = useDispatch();

  const fovImages = useSelector(state => state.cameraFovState.fovImages);
  const isConnected = selectedCamera?.connectionState === "Connected";
  const deviceId = selectedCamera?.deviceId;
  const hasPanoUrl = fovImages.byDeviceId[deviceId]?.pano;
  const pointsOfInterest = poiList.sort((a, b) => Number(a) - Number(b));
  const selectedFrame = pointsOfInterest.indexOf(selectedPoi.toString());

  const handleSelectPoi = ({ idx }) => setSelectedPoi(pointsOfInterest[idx]);

  useEffect(() => {
    if (!hasPanoUrl) {
      dispatch(CameraFovActions.getCameraFovImage({ deviceId }));
    }
  }, [deviceId, dispatch, hasPanoUrl]);

  return (
    <PointsOfInterestPanorama
      connected={isConnected}
      panoramaImageUrl={hasPanoUrl}
      onSelectPointOfInterest={handleSelectPoi}
      pointsOfInterest={pointsOfInterest}
      selectedFrame={selectedFrame}
    />
  );
};

PanoramaView.propTypes = {
  selectedCamera: PropTypes.object.isRequired,
  selectedPoi: PropTypes.number.isRequired,
  poiList: PropTypes.array.isRequired,
  setSelectedPoi: PropTypes.func.isRequired
};
