import AddIcon from "@mui/icons-material/Add";
import { Box, Fade } from "@mui/material";
import MaterialButton from "@mui/material/Button";
import { styled } from "@mui/styles";
import noop from "lodash/noop";
import PropTypes from "prop-types";

import { UnsavedStateBadge } from "../styled-components";

const Button = styled(MaterialButton)(({ theme, active, disabled }) => ({
  display: "relative",
  width: 62,
  height: 62,
  border: `
    1px
    solid
    ${active ? theme.palette.background.default : theme.palette.grey[500]}
  `,
  fontSize: 16,
  marginRight: theme.spacing(1.5),
  background: active && theme.palette.background.default,
  color: disabled ? theme.palette.grey[500] : theme.palette.text.primary,

  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.08)"
  }
}));

const PointsOfInterestPickerProps = {
  maxCount: PropTypes.number,
  onAddPointOfInterest: PropTypes.func,
  onSelectPointOfInterest: PropTypes.func,
  pointsOfInterest: PropTypes.array,
  readOnly: PropTypes.bool,
  selectedFrame: PropTypes.number,
  touchedFrames: PropTypes.object,
  disabled: PropTypes.bool
};

export const PointsOfInterestPicker = ({
  maxCount = 8,
  onAddPointOfInterest = noop,
  onSelectPointOfInterest = noop,
  pointsOfInterest = [],
  readOnly = false,
  selectedFrame = 0,
  touchedFrames = {},
  disabled
}) => {
  const disableAdd = pointsOfInterest?.length > maxCount - 1 || disabled;

  const handleSelectPointOfInterest = idx => event => {
    const payload = {
      idx,
      panCenterPosition: pointsOfInterest[idx]
    };

    onSelectPointOfInterest(payload, event);
  };

  return (
    <Box display="inline-flex">
      {pointsOfInterest?.map((pointOfInterest, idx) => {
        const isTouched = touchedFrames[idx];
        const isActive = selectedFrame === idx;

        return (
          <Button
            active={+isActive}
            disabled={isActive}
            key={idx}
            onClick={handleSelectPointOfInterest(idx)}
          >
            {idx + 1}
            <Fade in={isTouched} timeout={300}>
              <UnsavedStateBadge />
            </Fade>
          </Button>
        );
      })}

      {!readOnly && !disableAdd && (
        <Button onClick={onAddPointOfInterest}>
          <AddIcon />
        </Button>
      )}
    </Box>
  );
};

PointsOfInterestPicker.propTypes = PointsOfInterestPickerProps;
