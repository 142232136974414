import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import DetectionBox from "~/common/components/Dialog/Detectionbox/DetectionBox";
import LeakSourceBox from "~/containers/LeakSourceLocationTool/components/LeakSourceBox";
import LeakSourcePoint from "~/containers/LeakSourceLocationTool/components/LeakSroucePoint";

import { Image } from "./Image";
import { useStyles } from "./styles";

const AlarmImages = ({
  alarmFrames,
  alarmFramesNum,
  relevantFrames,
  imageRefs,
  handleEditAlarmTime,
  setHoveredScan,
  editingAlarmDate,
  formatISO,
  startDate,
  endDate,
  leakRois,
  leakSource,
  isMLAssistedReview = false
}) => {
  const showDetectionBoxes =
    sessionStorage.getItem("showDetectionBoxes") === "false" ? false : true;
  const [imagesLoaded, setImagesLoaded] = useState([]);
  const { classes } = useStyles();

  useEffect(() => {
    setImagesLoaded(
      alarmFrames?.map(() => {
        return false;
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alarmFrames.length]);

  return (
    <div className={classes.root}>
      <div className={classes.headerInfo}>
        <Typography variant="h6">
          {alarmFrames.length} total images, {alarmFramesNum} alarm image
          {alarmFramesNum !== 1 && "s"}
        </Typography>
      </div>
      {relevantFrames?.length > 0 && (
        <div className={classes.imageGrid} data-testid="alarm-images-grid">
          {relevantFrames.map(frame => {
            const frameDetections = isMLAssistedReview
              ? frame?.mlDetection?.mlDetections
              : frame?.detections;
            return (
              <div key={frame.id} className={classes.singleImageBlock}>
                <Image
                  data-testid="alarm-image"
                  frame={frame}
                  imageRefs={imageRefs}
                  alarmFrames={alarmFrames}
                  imagesLoaded={imagesLoaded}
                  setImagesLoaded={setImagesLoaded}
                  handleEditAlarmTime={handleEditAlarmTime}
                  setHoveredScan={setHoveredScan}
                  editingAlarmDate={editingAlarmDate}
                  formatISO={formatISO}
                  startDate={startDate}
                  endDate={endDate}
                  isMLAssistedReview={isMLAssistedReview}
                />
                {frameDetections.length > 0 &&
                  showDetectionBoxes &&
                  imagesLoaded[
                    alarmFrames.findIndex(fr => fr?.id === frame?.id)
                  ] &&
                  frameDetections.map((detection, index) => {
                    return (
                      <DetectionBox
                        key={detection.uuid}
                        img={
                          imageRefs[
                            alarmFrames.findIndex(fr => fr?.id === frame?.id)
                          ]
                        }
                        detection={detection}
                        index={index}
                        scan={frame}
                      />
                    );
                  })}
                {imagesLoaded[
                  alarmFrames.findIndex(fr => fr?.id === frame?.id)
                ] && (
                  <>
                    {leakRois?.length > 0 && (
                      <LeakSourceBox
                        key={frame?.id}
                        img={
                          imageRefs[
                            alarmFrames.findIndex(fr => fr?.id === frame?.id)
                          ]
                        }
                        scan={frame}
                        leakSource={leakRois?.at(0)}
                      />
                    )}
                    {leakSource?.coords?.length > 0 && (
                      <LeakSourcePoint
                        key={`leak-source-point-list-${frame?.id}`}
                        img={
                          imageRefs[
                            alarmFrames.findIndex(fr => fr?.id === frame?.id)
                          ]
                        }
                        leakOrigin={leakSource?.coords}
                      />
                    )}
                  </>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

AlarmImages.propTypes = {
  alarmFrames: PropTypes.array.isRequired,
  alarmFramesNum: PropTypes.number.isRequired,
  relevantFrames: PropTypes.array.isRequired,
  imageRefs: PropTypes.array.isRequired,
  handleEditAlarmTime: PropTypes.func.isRequired,
  setHoveredScan: PropTypes.func.isRequired,
  editingAlarmDate: PropTypes.string.isRequired,
  formatISO: PropTypes.func.isRequired,
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.oneOf([null])
  ]).isRequired,
  endDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.oneOf([null])
  ]).isRequired,
  leakRois: PropTypes.object.isRequired,
  leakSource: PropTypes.object.isRequired,
  isMLAssistedReview: PropTypes.bool
};

export default AlarmImages;
