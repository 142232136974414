import { useFeatureIsOn } from "@growthbook/growthbook-react";
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Slider,
  Switch,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { makeStyles } from "@kuva/ui-components";

import { useAlgorithmRecords } from "~/hooks";

import { DuplicateAlarmsSetting } from "./DuplicateAlarmsSetting";
import { UnitsSettings } from "./UnitsSettings";

const cleanString = str => str.replace(/_/g, " ");
const useStyles = makeStyles()({
  root: {
    padding: "2rem",
    color: "white"
  }
});

const Settings = ({
  setEventConf,
  setMinuteGap,
  setFrameMinimum,
  setPaddedFrames
}) => {
  const { records, selectedRecord, selectRecord, fetchRecords } =
    useAlgorithmRecords();
  const { classes } = useStyles();
  const [tmpEventConf, setTmpEventConf] = useState(50);
  const [tmpMinuteGap, setTmpMinuteGap] = useState(4);
  const [tmpFrameMinimum, setTmpFrameMinimum] = useState(4);
  const [tmpPaddedFrames, setTmpPaddedFrames] = useState(0);
  const [tmpContainer, setTmpContainer] = useState(selectedRecord.hash);
  const showBoxes =
    sessionStorage.getItem("showDetectionBoxes") === "false" ? false : true;

  const [tmpShowDetectionBoxes, setTmpShowDetectionBoxes] = useState(showBoxes);
  const [showDetectionBoxes, setShowDetectionBoxes] = useState(showBoxes);
  const handleEventConf = event => setTmpEventConf(event.target.value);
  const handleMinuteGap = event => setTmpMinuteGap(event.target.value);
  const handleMinuteGapSlider = (event, value) => setTmpMinuteGap(value);
  const handleFrameMinum = event => setTmpFrameMinimum(event.target.value);
  const handleFrameMinumSlider = (event, value) => setTmpFrameMinimum(value);
  const handlePaddedFrames = event => setTmpPaddedFrames(event.target.value);
  const handleEventConfSlider = (event, value) => setTmpEventConf(value);
  const handlePaddedFramesSlider = (event, value) => setTmpPaddedFrames(value);
  const handleContainer = event => setTmpContainer(event.target.value);
  const handleshowDetectionBoxes = (event, value) =>
    setTmpShowDetectionBoxes(value);

  const handleSettingsUpdate = () => {
    selectRecord(tmpContainer);

    setEventConf(tmpEventConf);
    setMinuteGap(tmpMinuteGap);
    setFrameMinimum(tmpFrameMinimum);
    setPaddedFrames(tmpPaddedFrames);
    setShowDetectionBoxes(tmpShowDetectionBoxes);
  };

  const handleSettingsReset = () => {
    setTmpEventConf(50);
    setTmpMinuteGap(4);
    setTmpFrameMinimum(4);
    setTmpPaddedFrames(0);
    setTmpContainer(selectedRecord.hash);
    setTmpShowDetectionBoxes(true);
    handleSettingsUpdate();
  };
  useEffect(() => {
    sessionStorage.setItem("showDetectionBoxes", showDetectionBoxes);
  }, [showDetectionBoxes]);

  useEffect(() => {
    try {
      fetchRecords();
    } catch (e) {
      console.log("error with request", e);
    }
  }, [fetchRecords]);

  return (
    <div className={classes.root}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Tooltip title="Confidence threshold used in event generation algorithm">
              <Typography> Event Confidence Threshold</Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <TextField
              variant="standard"
              value={tmpEventConf}
              onChange={handleEventConf}
              size="small"
              type="number"
              data-testid="event-confidence-threshold"
            />
          </Grid>
          <Grid item xs={8}>
            <Slider
              min={0}
              max={250}
              value={tmpEventConf}
              onChange={handleEventConfSlider}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="The maximum gap allowed between consecutive frames to qualify as an event">
              <Typography> Max Gap Minutes</Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <TextField
              variant="standard"
              value={tmpMinuteGap}
              onChange={handleMinuteGap}
              size="small"
              type="number"
              data-testid="max-gap"
            />
          </Grid>
          <Grid item xs={8}>
            <Slider
              min={0}
              max={20}
              value={tmpMinuteGap}
              onChange={handleMinuteGapSlider}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="The minimum amount of detected frames to qualify as an event">
              <Typography> Event Frame Minimum </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <TextField
              variant="standard"
              value={tmpFrameMinimum}
              onChange={handleFrameMinum}
              size="small"
              type="number"
              data-testid="event-frame-minimum"
            />
          </Grid>
          <Grid item xs={8}>
            <Slider
              min={0}
              max={20}
              value={tmpFrameMinimum}
              onChange={handleFrameMinumSlider}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="The number of previous and next frames to tack on to the end of each event (0-5)">
              <Typography> Padded Number of Frames </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <TextField
              variant="standard"
              value={tmpPaddedFrames}
              onChange={handlePaddedFrames}
              size="small"
              type="number"
              data-testid="padded-frames"
            />
          </Grid>
          <Grid item xs={8}>
            <Slider
              min={0}
              max={5}
              value={tmpPaddedFrames}
              onChange={handlePaddedFramesSlider}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="The identifier for the blob storage container to look at">
              <Typography> Blob Container </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={10}>
            <TextField
              variant="standard"
              select
              value={tmpContainer}
              onChange={handleContainer}
              size="small"
            >
              {records?.map(algo => (
                <MenuItem key={algo.hash} value={algo.hash}>
                  {cleanString(algo.alias)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="This determines whether or not detection boxes are shown on column density images.">
              <Typography> Show Detection Boxes </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={10}>
            <Switch
              color="primary"
              checked={tmpShowDetectionBoxes}
              onChange={handleshowDetectionBoxes}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={handleSettingsUpdate}
              variant="contained"
              style={{ width: "40%" }}
              color="primary"
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={handleSettingsReset}
              variant="contained"
              style={{ width: "40%" }}
              color="secondary"
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <Box>
          <Typography sx={{ marginTop: "2%" }} variant="h5">
            General
          </Typography>
          <Divider sx={{ width: "100%", marginBottom: "10px" }} />
          <UnitsSettings />
          <DuplicateAlarmsSetting />
        </Box>
      </div>
    </div>
  );
};

Settings.propTypes = {
  setEventConf: PropTypes.func.isRequired,
  setMinuteGap: PropTypes.func.isRequired,
  setFrameMinimum: PropTypes.func.isRequired,
  setPaddedFrames: PropTypes.func.isRequired
};

export default Settings;
