import { useAuth0 } from "@auth0/auth0-react";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import CssBaseline from "@mui/material/CssBaseline";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import {
  makeStyles,
  SnackbarNotification,
  withTheme
} from "@kuva/ui-components";

import {
  addEditAlarm as addEditAlarmAction,
  deleteAlarmByID as deleteAlarmAction
} from "~/common/actions/alarmsActions";
import { getCameras as getCamerasAction } from "~/common/actions/cameraActions";
import { getShiftByStatus } from "~/common/actions/shiftsActions";
import Loading from "~/common/components/LoadingIndicator";
import ShiftWidget from "~/common/components/ShiftWidget";
import {
  getPagination,
  getSelectedAlarm
} from "~/common/selectors/AlarmSelector";
import { getSelectedCamera } from "~/common/selectors/CameraSelector";
import { selectCurrentSession } from "~/common/selectors/SessionSelector";
import { AlarmCreationActions } from "~/common/store/slices/alarm-creation";
import { flags } from "~/constants/feature-flags";
import { shiftStatuses } from "~/constants/reviewerSessions";
import { useOrganization, useSessionState } from "~/hooks";
import useGetAlarms from "~/hooks/useGetAlarms";
import Alarms from "~/pages/alarmPage";
import { CameraPresetListsLoadable } from "~/pages/CameraPresetListsPage";
import TaggingPage from "~/pages/TaggingPage";
import SessionLandingPage from "~/pages/SessionLandingPage/Loadable";
import SessionParameterPage from "~/pages/SessionParameterPage/Loadable";
import SessionReviewerPage from "~/pages/SessionReviewerPage/Loadable";
import Settings from "~/pages/settingsPage/Settings";

import Header from "./Header";
import NavigationBar from "./NavigationBar";

const drawerWidth = "80px";

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
    overflow: "auto"
  },
  content: {
    position: "absolute",
    width: `calc(100% - ${drawerWidth})`,
    height: "calc(100% - 4em)",
    paddingTop: spacing(2),
    bottom: 0,
    left: drawerWidth
  }
}));

const MainContent = () => {
  const { classes } = useStyles();
  const isCameraPresetListsMgmtOn = useFeatureIsOn(
    flags.CAMERA_PRESET_LISTS_MGMT
  );
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const history = useHistory();
  const { logout } = useAuth0();

  const APP_SESSION_INACTIVITY_LIMIT = 60; /* 1 minute - TODO: move to app/env settings */
  const [remainingTime, setRemainingTime] = useState(Number.MAX_SAFE_INTEGER);
  const pagination = useSelector(getPagination, shallowEqual);
  const selectedAlarm = useSelector(getSelectedAlarm, shallowEqual);

  const { organizations, selectedOrg, baseOrg } = useOrganization();
  const selectedCamera = useSelector(getSelectedCamera, shallowEqual);
  const currentSession = useSelector(selectCurrentSession, shallowEqual);
  const { loadInitialAlarms } = useGetAlarms(selectedCamera?.deviceId);

  const [loading, setLoading] = useState(false);
  const showNonDetections = false;
  const [eventConf, setEventConf] = useState(50);
  const [minuteGap, setMinuteGap] = useState(4);
  const [frameMinimum, setFrameMinimum] = useState(4);
  const [paddedFrames, setPaddedFrames] = useState(0);
  const [endDate, setEndDate] = useSessionState("endDate", new Date());
  const [sessionDeviceId] = useSessionState("deviceId");
  const [reviewerMode, setReviewerMode] = useSessionState(
    "reviewerMode",
    "REVIEWER"
  );
  const [eventsLoading, setEventsLoading] = useState(false);
  const isLessThanInactivityLimit =
    remainingTime < APP_SESSION_INACTIVITY_LIMIT;

  const handleOnIdle = () =>
    logout({ logoutParams: { returnTo: window.location.origin } });

  const handleOnActive = () => {};

  const handleOnAction = () => {
    if (isLessThanInactivityLimit) {
      setRemainingTime(Number.MAX_SAFE_INTEGER);
    }
  };

  const handleDateChange = date => {
    const end = moment.utc(date).endOf("day");
    setEndDate(end.toISOString());
  };

  useEffect(() => {
    if (!selectedOrg?.id) return;
    dispatch({
      type: "FETCH_ALARMS_BY_PAGE_SUCCESS",
      alarms: [],
      pagination: {},
      resetRequired: true
    });
  }, [selectedOrg?.id, dispatch]);

  const { getRemainingTime } = useIdleTimer({
    timeout: process.env.REACT_APP_TIMEOUT,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  });

  const upsertAlarm = alarm => {
    dispatch(addEditAlarmAction(selectedCamera.deviceId, alarm))
      .then(res => {
        if (res === "Alarm has been added to the saving queue") {
          resetAlarmCreationSession();
          loadInitialAlarms();
          enqueueSnackbar("Request for add/update alarm sent.", {
            variant: "success"
          });
        }
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar("Error, Add/Update alarm failed!", {
          variant: "error"
        });
        return false;
      })
      .finally(() => {
        dispatch(AlarmCreationActions.resetAlarmCreation());
      });
    dispatch({ type: "SET_IS_EDITING", isEditing: false });

    return true;
  };

  /**
   * @name addEditAlarm
   * @description this function calls the add edit Alarm action that returns a 200 or an error
   * if the call is successful
   * 1. the new alarm will be updated in the redux
   * 2. it will show a snackbar
   * 3. get an updated list of cameras from the backend
   * 4. reset the creation session
   *
   * @author Sean W.
   * @date 25/03/2022
   * @param {object} alarm - the alarm to be saved
   */

  const addEditAlarm = alarm => {
    if (alarm?.id) {
      confirm({
        title: "Update Alarm",
        description:
          "This will trigger the re-quantification process, are you sure you want to proceed?"
      })
        .then(() => {
          // update alarm
          return upsertAlarm(alarm);
        })
        .catch(e => console.log(e));
    } else {
      //create alarm
      return upsertAlarm(alarm);
    }
  };

  /**
   * @name deleteAlarm
   * @description this function calls the delete Alarm action that returns a 200 or an error
   * if the call is successful
   * 1. the selected alarm will be cleared in the redux
   * 2. it will show a snackbar
   * 3. get an updated list of cameras from the backend
   * @author Sean W.
   * @date 25/03/2022
   * @param {string} id - the alarm to be saved
   */
  const deleteAlarm = id => {
    // show a loading spinner
    setLoading(true);
    // send an action to delete the alarm
    dispatch(deleteAlarmAction(selectedCamera.deviceId, id))
      .then(() => {
        loadInitialAlarms();
        // deselect the alarm in the url
        history.push(`/alarms/${selectedOrg?.id}/${selectedCamera?.deviceId}`);
        // if successful show a success snackbar
        enqueueSnackbar("Alarm deleted!", {
          variant: "success"
        });
      })
      .catch(error => {
        console.error(error);
        enqueueSnackbar("Error, delete alarm failed!", {
          variant: "error"
        });
      })
      .finally(() => {
        // remove the loading spinner
        setLoading(false);
      });
  };

  const resetAlarmCreationSession = () => {
    sessionStorage.removeItem("isAlarmBeingCreated");
    sessionStorage.removeItem("alarmStart");
    sessionStorage.removeItem("alarmEnd");
  };

  useEffect(() => {
    if (endDate && endDate !== "") {
      handleDateChange(endDate);
    } else {
      handleDateChange(new Date());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const seconds = Math.round(getRemainingTime() / 1000);
      if (seconds < APP_SESSION_INACTIVITY_LIMIT) {
        setRemainingTime(seconds);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [getRemainingTime, remainingTime]);

  /* Get Alarms only when camera is changed */
  useEffect(() => {
    loadInitialAlarms();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCamera?.deviceId]);

  // Reset scanIndex + POI
  useEffect(() => {
    // Use Route history if available otherwise use cache
    if (
      (history?.location?.state?.from &&
        history?.location?.state?.from?.deviceId !== sessionDeviceId) ||
      (selectedCamera?.deviceId && selectedCamera?.deviceId !== sessionDeviceId)
    ) {
      sessionStorage.removeItem("scanIndex");
      sessionStorage.removeItem("seletedPoi");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrg, selectedCamera, currentSession]);

  useEffect(() => {
    const org = selectedOrg || organizations?.at(0);
    if (baseOrg) {
      dispatch(getCamerasAction(org.id));
    }
  }, [selectedOrg, organizations, baseOrg, dispatch]);

  useEffect(() => {
    dispatch(getShiftByStatus(shiftStatuses.STARTED));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading />;
  return (
    <>
      {isLessThanInactivityLimit && (
        <SnackbarNotification
          message={`Logging you out due to inactivity in ${remainingTime} seconds`}
        />
      )}

      <div className={classes.root}>
        <Header reviewerMode={reviewerMode} />
        <ShiftWidget />
        <CssBaseline />
        <main className={classes.content}>
          <Switch>
            <Route exact path="/">
              <Redirect to="/events" />
            </Route>
            <Route path="/sessions-landing">
              <SessionLandingPage />
            </Route>
            <Route path="/sessions/:orgId">
              <SessionParameterPage />
            </Route>
            <Route path="/session-events/:orgId">
              <SessionReviewerPage
                eventsLoading={eventsLoading}
                showNonDetections={showNonDetections}
                eventConf={eventConf}
                minuteGap={minuteGap}
                frameMinimum={frameMinimum}
                paddedFrames={paddedFrames}
                setEventsLoading={setEventsLoading}
                handleDateChange={handleDateChange}
                addEditAlarm={addEditAlarm}
                endDate={endDate}
                resetAlarmCreationSession={resetAlarmCreationSession}
                selectedOrg={selectedOrg}
                setReviewerMode={setReviewerMode}
              />
            </Route>
            <Route path="/alarms/:orgId?/:deviceId?">
              <Alarms
                selectedAlarm={selectedAlarm}
                addEditAlarm={addEditAlarm}
                deleteAlarm={deleteAlarm}
                scresOptions={{
                  showNonDetections,
                  eventConf,
                  minuteGap,
                  frameMinimum,
                  paddedFrames
                }} //temp
                handleDateChange={handleDateChange}
                reviewerMode={reviewerMode}
              />
            </Route>
            <Route path="/settings">
              <Settings
                setEventConf={setEventConf}
                setMinuteGap={setMinuteGap}
                setFrameMinimum={setFrameMinimum}
                setPaddedFrames={setPaddedFrames}
              />
            </Route>
            {isCameraPresetListsMgmtOn && (
              <Route path="/camera-lists">
                <CameraPresetListsLoadable />
              </Route>
            )}
            <Route path="/events/:orgId?/:deviceId?">
              <TaggingPage
                eventsLoading={eventsLoading}
                showNonDetections={showNonDetections}
                eventConf={eventConf}
                minuteGap={minuteGap}
                frameMinimum={frameMinimum}
                paddedFrames={paddedFrames}
                setEventsLoading={setEventsLoading}
                handleDateChange={handleDateChange}
                addEditAlarm={addEditAlarm}
                endDate={endDate}
                resetAlarmCreationSession={resetAlarmCreationSession}
                setReviewerMode={setReviewerMode}
              />
            </Route>
            {/* Temporarily disabled */}
            {/* <Route path="/labeller/:orgId?/:deviceId?">
              <LabellerPage
                eventsLoading={eventsLoading}
                showNonDetections={showNonDetections}
                eventConf={eventConf}
                minuteGap={minuteGap}
                frameMinimum={frameMinimum}
                paddedFrames={paddedFrames}
                setEventsLoading={setEventsLoading}
                handleDateChange={handleDateChange}
                addEditAlarm={addEditAlarm}
                endDate={endDate}
                resetAlarmCreationSession={resetAlarmCreationSession}
                setReviewerMode={setReviewerMode}
              />
            </Route> */}
          </Switch>
        </main>
      </div>
      <NavigationBar
        style={{ position: "absolute" }}
        drawerWidth={drawerWidth}
        alarmCount={pagination?.total}
        orgId={selectedOrg?.id || ""}
        deviceId={selectedCamera?.deviceId || ""}
      />
    </>
  );
};

MainContent.propTypes = {
  // setReportedAttributes: PropTypes.func.isRequired,
  // setLastHeartbeat: PropTypes.func.isRequired,
  // getOrgCameras: PropTypes.func.isRequired,
};

export default withTheme(MainContent);
