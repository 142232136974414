export const SCAN_RESULT_TYPES = Object.freeze({
  TAGGING: "tagging",
  SESSIONS: "sessions",
  LABELLER: "labeller"
});

export const DETECTION_MODES = Object.freeze({
  STANDARD: "standard-detection",
  ML: "ml-detection"
});

export const DETECTION_DELETE_MODE = Object.freeze({
  KEEP: "keep",
  DELETE: "delete"
});
