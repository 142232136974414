import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Avatar,
  Button,
  Divider,
  Tooltip,
  Typography,
  Box
} from "@mui/material";
import PropTypes from "prop-types";
import { shallowEqual, useSelector } from "react-redux";

import { getWindDirection, getWindSpeed } from "@kuva/ui-helpers";

import {
  ImageTypeSelect,
  MetadataInfo,
  PoiSelect,
  TagTextField,
  TelemetryInfo,
  DetectionToggle
} from "~/common/components/Bar/components";
import { KeepDeleteToggle } from "~/common/components/Bar/components/KeepDeleteToggle";
import { selectIsEditing } from "~/common/selectors/AlarmSelector";
import { getSelectedCamera } from "~/common/selectors/CameraSelector";
import { useScanResults } from "~/hooks";

import { useStyles } from "./styles";

export const Bar = ({
  scanResultType,
  displayType,
  handleImgTypeButton,
  imgType,
  selectedScan,
  isAlarmBeingCreated,
  handleCreatingAlarm,
  selectedPoi,
  setSelectedPoi,
  poiKeys,
  setScanIndex,
  tags,
  updateTags,
  setTagsFocus,
  confidence = 0
}) => {
  const { detectionMode, setDetectionMode } = useScanResults(scanResultType);

  const handleDetectionModeChange = (_event, detectionMode) =>
    setDetectionMode(detectionMode);

  const dividerStyle = { height: "3rem", width: 1, margin: "0 5px" };
  const avatarStyle = {
    fontSize: 13.33,
    height: 25,
    width: 25,
    background: "#05BEC3",
    margin: "0 5px",
    cursor: "pointer"
  };
  const { classes } = useStyles();
  const tooltipStyle = { background: "black" };
  const { telemetry } = selectedScan || {};
  const selectedCamera = useSelector(getSelectedCamera, shallowEqual);
  const isAlarmEditing = useSelector(selectIsEditing);

  return (
    <div className={classes.root}>
      <div className={classes.mainWrapper}>
        <>
          {displayType !== "panorama" && (
            <ImageTypeSelect
              handleImgTypeButton={handleImgTypeButton}
              imgType={imgType}
            />
          )}
          <Divider style={dividerStyle} />
          <PoiSelect
            displayType={displayType}
            selectedPoi={selectedPoi}
            setSelectedPoi={setSelectedPoi}
            poiKeys={poiKeys}
            setScanIndex={setScanIndex}
          />
          <Divider style={dividerStyle} />
        </>
        {displayType === "image" && (
          <>
            <Tooltip style={tooltipStyle} title="Shortcut: 'Ctrl + Z'">
              <span>
                <Button
                  style={{ width: "max-content" }}
                  disabled={isAlarmBeingCreated || isAlarmEditing}
                  variant="contained"
                  color="primary"
                  onClick={() => handleCreatingAlarm(selectedScan.createdOn)}
                  startIcon={<ErrorOutlineIcon />}
                >
                  Create Alarm
                </Button>
              </span>
            </Tooltip>
            <Tooltip
              style={tooltipStyle}
              title={
                <MetadataInfo
                  selectedScan={selectedScan}
                  currentImageConfidence={confidence}
                />
              }
            >
              <Avatar style={avatarStyle}>M</Avatar>
            </Tooltip>
            <Tooltip
              style={tooltipStyle}
              title={<TelemetryInfo telemetry={telemetry} />}
            >
              <Avatar style={avatarStyle}>T</Avatar>
            </Tooltip>
          </>
        )}

        {displayType !== "panorama" && (
          <TagTextField
            tags={tags}
            updateTags={updateTags}
            setTagsFocus={setTagsFocus}
            displayType={displayType}
          />
        )}
      </div>
      <div>
        {displayType !== "panorama" && (
          <>
            <Typography className={classes.imageInfo}>
              Wind Direction: {getWindDirection(selectedScan?.windDirection)}
            </Typography>
            <Typography className={classes.imageInfo}>
              Wind Speed: {getWindSpeed(selectedScan?.windSpeed, "m/s")}
            </Typography>
          </>
        )}

        {displayType === "image" && (
          <>
            <>
              <Typography className={classes.imageInfo}>
                Time: {selectedScan?.createdOn.substring(11, 19)}
              </Typography>
              <Typography className={classes.imageInfo}>
                Device ID: {selectedCamera?.deviceId}
              </Typography>
              <Typography className={classes.imageInfo}>
                Device Name: {selectedCamera?.name}
              </Typography>
              <Typography className={classes.imageInfo}>
                ML Score: {selectedScan?.mlScore?.score}
              </Typography>
            </>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              <DetectionToggle
                detectionMode={detectionMode}
                handleDetectionModeChange={handleDetectionModeChange}
                disabled={isAlarmBeingCreated || isAlarmEditing}
              />

              <KeepDeleteToggle scanResultType={scanResultType} />
            </Box>
          </>
        )}
      </div>
      {displayType === "carousel" && (
        <div className={classes.deviceData}>
          <Typography className={classes.font14}>
            Device ID: {selectedCamera?.deviceId}
          </Typography>
          <Typography className={classes.font14}>
            Device Name: {selectedCamera?.name}
          </Typography>
          <DetectionToggle
            detectionMode={detectionMode}
            handleDetectionModeChange={handleDetectionModeChange}
            disabled={isAlarmBeingCreated || isAlarmEditing}
          />
        </div>
      )}
    </div>
  );
};

Bar.propTypes = {
  scanResultType: PropTypes.string.isRequired,
  displayType: PropTypes.string.isRequired,
  handleImgTypeButton: PropTypes.func.isRequired,
  imgType: PropTypes.string.isRequired,
  selectedScan: PropTypes.object.isRequired,
  isAlarmBeingCreated: PropTypes.bool.isRequired,
  handleCreatingAlarm: PropTypes.func.isRequired,
  selectedPoi: PropTypes.number.isRequired,
  setSelectedPoi: PropTypes.func.isRequired,
  poiKeys: PropTypes.array.isRequired,
  setScanIndex: PropTypes.func.isRequired,
  currentImageConfidence: PropTypes.number.isRequired,
  tags: PropTypes.array.isRequired,
  updateTags: PropTypes.func.isRequired,
  setTagsFocus: PropTypes.func.isRequired,
  confidence: PropTypes.number
};
