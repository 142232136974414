import { makeStyles } from "@kuva/ui-components";

export const useStyles = makeStyles()(({ palette, spacing }) => ({
  root: {
    width: "100%",
    aspectRatio: "310 / 410",
    border: `1px solid ${palette.custom.grey}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    boxSizing: "border-box",
    position: "relative"
  },

  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
    height: "100%"
  },

  hoopImg: {
    maxWidth: "50%",
    height: "auto"
  },

  text: {
    color: palette.custom.grey,
    fontSize: 14,
    marginTop: spacing(1)
  }
}));
